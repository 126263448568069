import React from "react";

const OffCanvas = ({ showOffCanvas, setShowOffCanvas, children }) => {
  return (
    <div
      className={`offcanvas offcanvas-end ${showOffCanvas ? "show" : ""}`}
      tabIndex="-1"
    >
      <div className="offcanvas-header justify-content-end px-4">
        <button
          type="button"
          className="btn-close px-1 ms-0 mr-auto"
          onClick={() => setShowOffCanvas(false)}
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body py-0">{children}</div>
    </div>
  );
};

export default OffCanvas;
