import api from "./api";

const route = "/contratos";

export default {
  getContratos: (idProyecto) => api.get(`${route}/${idProyecto}`),
  getContrato: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/${idContrato}`),
  postContrato: (idProyecto, contrato) =>
    api.post(`${route}/${idProyecto}`, { ...contrato }),
  putContrato: (idProyecto, contrato) =>
    api.put(`${route}/${idProyecto}/${contrato.idContrato}`, { ...contrato }),
};
