import React, { useContext, useEffect } from "react";
import useProyecto from "../hooks/useProyecto";
import { CambioEstimacionVersionesContext } from "../context/CambioEstimacionVersionsContext";
import CambioEstimacionVersionCard from "../components/estimacionesversiones/CambioEstimacionVersionCard";

const HistorialEstimacionVersion = ({ idEstimacionVersion }) => {
  const { idProyecto } = useProyecto();
  const { cambioestimacionversiones, getCambioEstimacionVersiones } =
    useContext(CambioEstimacionVersionesContext);

  useEffect(() => {
    getCambioEstimacionVersiones(idProyecto, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstimacionVersion]);

  const renderCambios = () => {
    if (Array.isArray(cambioestimacionversiones)) {
      if (cambioestimacionversiones.length === 0) {
        return <p>No hay cambios registrados en esta versión de estimación</p>;
      }
      return cambioestimacionversiones.map((cambio) => (
        <CambioEstimacionVersionCard
          key={cambio.idCambioEstimacionVersion}
          cambioestimacionversion={cambio}
        />
      ));
    }
  };
  return (
    <div>
      <h1 className="h4">Historial de Cambios</h1>
      {renderCambios()}
    </div>
  );
};

export default HistorialEstimacionVersion;
