import React, { useState } from "react";
import usePreciario from "../../hooks/usePreciario";
import EstimacionesConcepto from "../estimaciones/EstimacionesConcepto";
import PillTabs from "../common/PillTabs";
import ConceptoInfo from "./ConceptoInfo";

const ConceptoDetail = () => {
  const [view, setView] = useState("detalles");
  const { concepto } = usePreciario();

  return (
    <div className="container-fluid px-0">
      <p>{concepto.nombre}</p>
      <div className="container-fluid mb-3">
        <PillTabs
          value={view}
          modifier={setView}
          tabs={[
            {
              value: "detalles",
              label: "Detalles",
            },
            {
              value: "estimaciones",
              label: "Estimaciones",
            },
          ]}
        />
      </div>
      {view === "detalles" ? <ConceptoInfo /> : <EstimacionesConcepto />}
    </div>
  );
};

export default ConceptoDetail;
