import React, { createContext, useReducer } from "react";
import { PushNotifications } from "@capacitor/push-notifications";
import {
  SET_DEVICE,
  SET_PLATFORM,
  SET_DEVICE_TOKEN,
  NOTIFICATIONS_REGISTERED,
} from "../types/capacitor";
import CapacitorReducer from "../reducers/CapacitorReducer";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";

const initialState = {
  device_token: null,
  registered: false,
  platform: null,
  device: null,
};

export const CapacitorContext = createContext(initialState);

export const CapacitorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CapacitorReducer, initialState);

  const setupPlatform = () => {
    const platform = Capacitor.getPlatform();
    dispatch({ type: SET_PLATFORM, payload: platform });
  };

  const setupStatusBar = () => {
    StatusBar.setStyle({ style: Style.Light });
  };

  const setupDevice = () => {
    Device.getInfo().then((info) => {
      dispatch({ type: SET_DEVICE, payload: info.model });
    });
  };

  const addListeners = async (platform) => {
    if (["ios", "android"].includes(platform)) {
      await PushNotifications.addListener("registration", (token) => {
        dispatch({ type: SET_DEVICE_TOKEN, payload: token.value });
      });

      await PushNotifications.addListener("registrationError", (err) => {
        console.error("Registration error: ", err.error);
      });

      await PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push notification received: ", notification);
        }
      );

      await PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log(
            "Push notification action performed",
            notification.actionId,
            notification.inputValue
          );
        }
      );
    }
  };

  const registerNotifications = async (platform) => {
    if (["ios", "android"].includes(platform)) {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== "granted") {
        throw new Error("User denied permissions!");
      }

      PushNotifications.register().then(() => {
        dispatch({ type: NOTIFICATIONS_REGISTERED });
        addListeners();
      });
    }
  };

  const getDeliveredNotifications = async (platform) => {
    if (["ios", "android"].includes(platform)) {
      const notificationList =
        await PushNotifications.getDeliveredNotifications();
      console.log("delivered notifications", notificationList);
    }
  };

  return (
    <CapacitorContext.Provider
      value={{
        ...state,
        addListeners,
        setupDevice,
        setupPlatform,
        setupStatusBar,
        registerNotifications,
        getDeliveredNotifications,
      }}
    >
      {children}
    </CapacitorContext.Provider>
  );
};
