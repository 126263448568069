
import {
  SET_USER_DEVICE,
  CREATE_USER_DEVICE,
  USER_DEVICES_RECEIVED,
  SET_PROPERTY_USER_DEVICE,
} from "../types/user_devices";

const schema = {

}

const user_devicesReducer = (state, { type, payload }) => {
  switch (type) {
    case USER_DEVICES_RECEIVED:
      return { ...state, user_devices: payload };
    case SET_USER_DEVICE:
      return { ...state, user_device: payload };
    case CREATE_USER_DEVICE:
      return { ...state, user_device: schema };
    case SET_PROPERTY_USER_DEVICE: {
      const { key, value } = payload;
      const user_device = { ...state.user_device };
      user_device[key] = value;
      return { ...state, user_device };
    }
    default:
      return { ...state};
  }
};

export default user_devicesReducer;
