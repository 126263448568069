import React, { useState, Fragment, useContext, useEffect } from "react";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { FILES_ENDPOINT, isSameDate } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { ReactComponent as PDFIcon } from "../../assets/pdf.svg";
import moment from "moment";
import { Link } from "react-router-dom";

const Mensaje = ({ idProyecto, idAdjunto, retraso, entrada, fechaAnterior }) => {
  const [src, setSrc] = useState("");
  const { user } = useContext(AuthContext);

  const { getAdjuntoProyecto, setSingleAdjunto } = useContext(AdjuntosContext);

  useEffect(() => {
    if (entrada && entrada !== null) {
      if (entrada.Adjunto && entrada.Adjunto !== null) {
        if (
          ["png", "jpeg", "jpg", "gif", "webp"].includes(entrada.Adjunto.tipo)
        ) {
          getAdjuntoProyecto(
            idProyecto,
            entrada.Adjunto.idAdjunto,
            handleAdjuntoCallback
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAdjuntoCallback(current) {
    setSrc(current);
  }

  function isFollowUp() {
    return (
      isSameDate(fechaAnterior, entrada.createdAt) && !retraso && fechaAnterior
    );
  }

  function renderUserImg() {
    if (entrada.Usuario && entrada.Usuario !== null) {
      if (entrada.Usuario.Adjunto !== null) {
        const { Adjunto } = entrada.Usuario;
        return (
          <img
            src={`${FILES_ENDPOINT}/${Adjunto.idAdjunto}.${Adjunto.tipo}`}
            className="bitacoraImage hide-mobile mt-3"
            style={{ boxShadow: "0 0 8px rgba(0,0,0,.2)", borderRadius: "50%" }}
            alt={Adjunto.nombre}
          />
        );
      }
    }
    return <i className="fa fa-user-circle fa-2x d-block m-auto"></i>;
  }

  function renderUser() {
    if (entrada.idUsuario !== user.idUsuario) {
      return <div className="col-1 text-center">{renderUserImg()}</div>;
    }
    return <div className="col"></div>;
  }

  function renderContenido() {
    if (entrada && entrada !== null) {
      if (entrada.contenido !== null && entrada.contenido !== "") {
        return <div className="card-text">{entrada.contenido}</div>;
      }
    }
  }

  function renderAdjunto() {
    if (entrada && entrada !== null) {
      if (entrada.Adjunto && entrada.Adjunto !== null && entrada.idAdjunto !== idAdjunto) {
        if (["pdf", "PDF"].includes(entrada.Adjunto.tipo)) {
          return (
            <div onClick={() => setSingleAdjunto(entrada.Adjunto)} className="row py-2 mb-2 mx-0 align-items-center bg-light br-10 border">
              <div className="col-3">
                <PDFIcon />
              </div>
              <div className="col-9">
                <p className="bold text-dark mb-1">{entrada.Adjunto.nombre}.{entrada.Adjunto.tipo}</p>
                <Link
                  to={`/obra/${idProyecto}/supervision/${entrada.Adjunto.AdjuntoFolders[0]?.idFolder}`}
                  className="small btn btn-sm px-0 d-inline-block text-capitalize text-dark text-decoration-underline"
                  onClick={e => e.stopPropagation()}
                >
                  <i className="fa fa-eye me-2" />
                  {entrada.Adjunto.AdjuntoFolders[0]?.Folder?.nombre ||
                    "Folder"} 
                </Link>
              </div>
            </div>
          );
        }
        if (src !== "") {
          return (
            <img
              src={src}
              alt="adjunto"
              className="mw-100 w-100 mb-2 adjunto-mensaje"
            />
          );
        }
        return (
          <div className="row mx-0 mb-2 align-items-center">
            <div className="spinner-border"></div>
            <span className="ml-3">Cargando adjunto...</span>
          </div>
        );
      }
    }
  }
  function renderName() {
    if (
      entrada.Usuario &&
      entrada.Usuario !== null &&
      entrada.idUsuario !== user.idUsuario
    ) {
      return <span className="small">{entrada.Usuario.nombre}:</span>;
    }
  }

  function renderFecha() {
    if (!isFollowUp()) {
      return (
        <div className="row mb-2 mb-md-3">
          <div className="container text-center my-2">
            <span className="bg-light small border p-2 shadow-sm round5">
              {moment(entrada.createdAt).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
      );
    }
  }

  const renderRubro = () => {
    let message = "";
    if (entrada.SubDestajo && entrada.SubDestajo !== null) {
      message = entrada.SubDestajo.nombre;
    } else if (entrada.Destajo && entrada.Destajo !== null) {
      message = entrada.Destajo;
    } else if (entrada.Partida && entrada.Partida !== null) {
      message = entrada.Partida.nombre;
    }
    if (message !== "") {
      return (
        <span
          className={`bg-${
            entrada.retraso ? "danger" : "black"
          } badge badge-pill mb-1`}
        >
          {message}
        </span>
      );
    }
  };

  const renderCol = () => {
    if (entrada.idUsuario !== user.idUsuario) {
      return <div className="col" />;
    }
  };

  return (
    <Fragment>
      {renderFecha()}
      <div className="row mb-2 mb-md-3" style={{ minHeight: 70 }}>
        {renderUser()}
        <div className="col-11 col-md-8" style={{ minWidth: 250 }}>
          {renderName()}
          <div
            className={entrada.idUsuario === user.idUsuario ? "text-end" : ""}
          >
            {renderRubro()}
          </div>
          <div
            className={`card shadow-sm msg-card py-3 ${
              entrada.idUsuario === user.idUsuario
                ? "bg-primary text-white me-0 ms-auto"
                : "bg-white ms-0 me-auto"
            }`}
          >
            <div className="container-fluid">
              {renderAdjunto()}
              {renderContenido()}
            </div>
            <div className="container-fluid mt-2">
              <p className="mb-0 small" style={{ minWidth: 42 }}>
                {entrada ? moment(entrada.createdAt).format("HH:mm") : ""}
              </p>
            </div>
          </div>
        </div>
        {renderCol()}
      </div>
    </Fragment>
  );
};

export default Mensaje;
