import React from "react";
import PartidaContratoMobile from "./PartidaContratoMobile";
import usePreciario from "../../hooks/usePreciario";
import ConceptoMobile from "./ConceptoMobile";
import { IonList } from "@ionic/react";

const PreciarioMobile = () => {
  const {
    partidas,
    conceptos,
    showConceptos,
    conceptosExtra,
    getConceptosPartida,
    toggleConceptosPartida,
  } = usePreciario();

  const renderPartidas = () => {
    if (Array.isArray(partidas)) {
      const components = [];
      const partidasRender = [...partidas];
      partidasRender.forEach((partida) => {
        components.push(
          <PartidaContratoMobile
            partida={partida}
            key={partida.idPartidaContrato}
            toggleConceptos={toggleConceptosPartida}
            collapsed={!showConceptos.includes(partida.idPartidaContrato)}
          />
        );
        if (showConceptos.includes(partida.idPartidaContrato)) {
          const currentConceptos = getConceptosPartida(
            partida.idPartidaContrato
          );
          currentConceptos.forEach((concepto) => {
            components.push(
              <ConceptoMobile concepto={concepto} key={`concepto-${concepto.idConcepto}`} />
            );
          });
        }
      });
      if (Array.isArray(conceptosExtra)) {
        conceptosExtra.forEach((conceptoExtra) =>
          components.push(
            <ConceptoMobile
              concepto={conceptoExtra}
              key={`concepto-${conceptoExtra.idConcepto}`}
            />
          )
        );
      }
      if (
        components.length === 0 &&
        Array.isArray(partidas) &&
        Array.isArray(conceptos)
      ) {
        return (
          <p className="mx-2 px-3 small">
            No encontramos resultados para tu búsqueda.
          </p>
        );
      }
      return components;
    }
  };

  return (
    <IonList id="preciario-mobile" style={{ overflowY: "auto", overflowX: "hidden" }}>
      {renderPartidas()}
    </IonList>
  );
};

export default PreciarioMobile;
