import api from "./api";

const route = "/cambiosestimacionversion";

export default {
  getCambioEstimacionVersiones: (idProyecto, idEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/historial/${idEstimacionVersion}`),
  getSingleCambioEstimacionVersion: (idProyecto, idCambioEstimacionVersion) =>
    api.get(`${route}/${idProyecto}/${idCambioEstimacionVersion}`),
  postCambioEstimacionVersion: (cambioestimacionversion) =>
    api.post(route, { ...cambioestimacionversion }),
  verifyCambioEstimacionVersion: (
    idProyecto,
    idCambioEstimacionVersion,
    code
  ) =>
    api.post(`${route}/${idProyecto}/${idCambioEstimacionVersion}/verify`, {
      idCambioEstimacionVersion,
      code,
    }),
  postNotificacionesCambioEstimacionVersion: (
    idProyecto,
    idCambioEstimacionVersion
  ) =>
    api.post(
      `${route}/${idProyecto}/${idCambioEstimacionVersion}/notifications`
    ),
  putCambioEstimacionVersion: (cambioestimacionversion) =>
    api.put(route, { ...cambioestimacionversion }),
  deleteCambioEstimacionVersion: (cambioestimacionversion) =>
    api.delete(`${route}/${cambioestimacionversion}`),
};
