import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { FILES_ENDPOINT } from "../../utils";
import UserForm from "../UserForm";

const User = () => {
  const { user, getUsuario, updateUsuario } = useContext(AuthContext);

  const { modalComponent } = useContext(ModalContext);

  const getUserImgSrc = () => {
    if (user && user !== null) {
      if (user.idAdjunto !== null && user.Adjunto) {
        return `${FILES_ENDPOINT}/${user.idAdjunto}.${user.Adjunto.tipo}`;
      }
    }
  };

  const handleEdit = () => {
    modalComponent(
      "Editar Perfil",
      <UserForm />,
      getUsuario,
      handleEditCallback
    );
  };

  const handleEditCallback = () => {
    updateUsuario(user);
    getUsuario();
  };

  return (
    <div className="container-fluid obra-details" style={{ maxWidth: 150 }}>
      <img
        src={getUserImgSrc()}
        className="mw-100 w-100 d-block m-auto"
        alt={user.nombre}
      />
      <div className="row">
        <div className="container-fluid text-end">
          <button className="btn btn-light shadow" onClick={handleEdit}>
            <i className="fas fa-pen" />
          </button>
        </div>
      </div>
      <h5 className="mt-3 bold text-center">{user.nombre}</h5>
    </div>
  );
};

export default User;
