import React, { useContext } from "react";
import EstimacionVersionHeader from "./EstimacionVersionHeader";
import { CapacitorContext } from "../../context/CapacitorContext";
import FirmasEstimacionVersionMobile from "./FirmasEstimacionVersionMobile";
import FirmasEstimacionVersion from "./FirmasEstimacionVersion";

const EstimacionVersionPortada = () => {
  const { platform } = useContext(CapacitorContext);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container-fluid px-0">
          <EstimacionVersionHeader
            title="HOJA DE PORTADA"
            view="portada"
            pages="3"
            page="1"
          />
        </div>
      </div>
      {platform !== "web" ? (
        <FirmasEstimacionVersionMobile />
      ) : (
        <FirmasEstimacionVersion />
      )}
    </div>
  );
};

export default EstimacionVersionPortada;
