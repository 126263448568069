import React, { useContext, useEffect } from "react";
import CambioEstimacionVersionCard from "../components/estimacionesversiones/CambioEstimacionVersionCard";
import EstimacionVersionHeader from "../components/estimacionesversiones/EstimacionVersionHeader";
import { CambioEstimacionVersionesContext } from "../context/CambioEstimacionVersionsContext";
import { EstimacionVersionesContext } from "../context/EstimacionVersionesContext";
import { EstimacionesContext } from "../context/EstimacionesContext";
import StatusBadge from "../components/common/StatusBadge";
import useProyecto from "../hooks/useProyecto";
import { useParams } from "react-router-dom";
import moment from "moment";

const SingleCambioEstimacionVersion = () => {
  const { idProyecto } = useProyecto();
  const { estimacion, selectEstimacion } = useContext(EstimacionesContext);
  const {
    idContrato,
    idEstimacion,
    idEstimacionVersion,
    idCambioEstimacionVersion,
  } = useParams();
  const { getSingleEstimacionVersion } = useContext(EstimacionVersionesContext);
  const {
    cambioestimacionversion,
    cambioestimacionversiones,
    getCambioEstimacionVersiones,
    getSingleCambioEstimacionVersion,
  } = useContext(CambioEstimacionVersionesContext);

  useEffect(() => {
    selectEstimacion(idProyecto, idContrato, idEstimacion);
    getCambioEstimacionVersiones(idProyecto, idEstimacionVersion);
    getSingleCambioEstimacionVersion(idProyecto, idCambioEstimacionVersion);
    getSingleEstimacionVersion(idProyecto, idContrato, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCambioEstimacionVersion]);

  const getNumero = () => {
    if (Array.isArray(cambioestimacionversiones)) {
      const cambios = cambioestimacionversiones.sort((a, b) =>
        moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1
      );
      const index = cambios.findIndex(
        (cambio) =>
          cambio.idCambioEstimacionVersion ===
          parseInt(idCambioEstimacionVersion)
      );
      if (index !== -1) return index + 1;
    }
  };

  const renderCambio = () => {
    if (cambioestimacionversion && cambioestimacionversion !== null) {
      return (
        <div className="row mt-3">
          <CambioEstimacionVersionCard
            cambioestimacionversion={cambioestimacionversion}
          />
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-6">
          <h1 className="h3 mb-1">Cambio #{getNumero()}</h1>
          <h2 className="h5 mb-1">Estimacion #{estimacion?.numero}</h2>
        </div>
        <div className="col-6 text-end">
          <StatusBadge
            text={cambioestimacionversion?.status}
            color={cambioestimacionversion?.status === "Alterado" ? "danger" : "success"}
          />
        </div>
      </div>
      <div className="card p-3 px-4 shadow">
        {renderCambio()}
        {cambioestimacionversion?.status === "Alterado" && (
          <p className="text-danger">
            El sello del cambio no corresponde con su estado actual. Fue modificado posterior a su verificación.
          </p>
        )}
        <EstimacionVersionHeader
          title="CAMBIO DE ESTADO DE VERSIÓN"
          view="portada"
          pages="1"
        />
      </div>
    </div>
  );
};

export default SingleCambioEstimacionVersion;
