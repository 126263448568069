import React, { useContext, useEffect } from "react";
import { CambioEstimacionVersionesContext } from "../context/CambioEstimacionVersionsContext";
import useProyecto from "./useProyecto";
import { useParams } from "react-router-dom";
import { AppConfigContext } from "../context/AppConfigContext";
import QRCode from "react-qr-code";

const useFirmas = () => {
  const { idProyecto } = useProyecto();
  const { production_url } = useContext(AppConfigContext);
  const { idContrato, idEstimacion, idEstimacionVersion } = useParams();
  const { cambioestimacionversiones, getCambioEstimacionVersiones } =
    useContext(CambioEstimacionVersionesContext);

  const base_url = `${production_url}/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${idEstimacion}/versiones/${idEstimacionVersion}/cambio`;

  useEffect(() => {
    getCambioEstimacionVersiones(idProyecto, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idEstimacionVersion]);

  const renderQRCode = (cambio) => {
    return (
      <QRCode
        value={`${base_url}/${cambio.idCambioEstimacionVersion}`}
        key={cambio.idCambioEstimacionVersion}
        className="border mw-100 mb-3"
        height="auto"
      />
    );
  };

  const renderFirmaSupervision = () => {
    const cambioSupervisor = cambioestimacionversiones?.find(
      (cambio) =>
        cambio.estado_anterior === "enviada" &&
        ["revisada", "revisada-sin-supervisor"].includes(cambio.estado_nuevo)
    );
    if (cambioSupervisor && cambioSupervisor !== null) {
      if (cambioSupervisor.hash !== null) return renderQRCode(cambioSupervisor);
    }
  };

  const renderFirmaContratista = () => {
    const enviadaContratista = cambioestimacionversiones?.find(
      (cambio) =>
        cambio.estado_anterior === "creada" && cambio.estado_nuevo === "enviada"
    );
    const conciliadaSupervisor = cambioestimacionversiones?.find(
      (cambio) =>
        ["revisada", "revisada-sin-supervisor"].includes(
          cambio.estado_anterior
        ) &&
        [
          "conciliada",
          "conciliada-sin-contratista",
          "conciliada-sin-supervisor",
        ].includes(cambio.estado_nuevo)
    );
    const conciliadaObras = cambioestimacionversiones?.find(
      (cambio) =>
        ["autorizada", "autorizada-sin-obras"].includes(
          cambio.estado_anterior
        ) &&
        [
          "conciliada-con-obras",
          "conciliada-con-obras-sin-contratista",
        ].includes(cambio.estado_nuevo)
    );
    const cambios = [
      enviadaContratista,
      conciliadaSupervisor,
      conciliadaObras,
    ].filter((item) => item && item !== null);
    return cambios
      .map((cambio, index) =>
        cambio.hash !== null ? renderQRCode(cambio) : null
      )
      .filter((item) => item !== null);
  };

  const renderFirmaObras = () => {
    const cambioObras = cambioestimacionversiones?.find(
      (cambio) =>
        [
          "conciliada",
          "conciliada-sin-contratista",
          "conciliada-sin-supervisor",
        ].includes(cambio.estado_anterior) &&
        ["autorizada", "autorizada-sin-obras"].includes(cambio.estado_nuevo)
    );
    if (cambioObras && cambioObras !== null) {
      if (cambioObras.hash !== null) return renderQRCode(cambioObras);
    }
  };

  const renderFirmaControl = () => {
    const cambioFinanzas = cambioestimacionversiones?.find(
      (cambio) => cambio.Usuario?.defaultType === 4
    );
    if (cambioFinanzas && cambioFinanzas !== null) {
      if (cambioFinanzas.hash !== null) return renderQRCode(cambioFinanzas);
    }
  };

  const firmas = [
    {
      name: "SUPERVISIÓN DE EMPRESA CONTRATISTA",
      renderFunction: renderFirmaContratista,
    },
    {
      name: "GERENCIA DE OBRAS E INSTALACIONES Y/O MANTENIMIENTO DE SUPERVISIÓN",
      renderFunction: renderFirmaSupervision,
    },
    {
      name: "GERENCIA DE ADMON Y CONTROL DE GERENCIA DE OBRAS E INSTALACIONES Y/O MANTENIMIENTO",
      renderFunction: renderFirmaObras,
    },
    {
      name: "Vo.Bo. GERENCIA DE ADMINISTRACIÓN Y CONTROL",
      renderFunction: renderFirmaControl,
    },
  ];

  return {
    firmas,
    renderFirmaObras,
    renderFirmaSupervision,
    renderFirmaContratista,
    renderFirmaControl,
  };
};

export default useFirmas;
