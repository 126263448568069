import React, { useContext, useEffect } from "react";
import ConceptosEstimacion from "../conceptos/ConceptosEstimacion";
import { ConceptosContext } from "../../context/ConceptosContext";
import EstimacionVersionHeader from "./EstimacionVersionHeader";
import { ModalContext } from "../../context/ModalContext";
import useProyecto from "../../hooks/useProyecto";
import ConceptosEstimacionMobile from "../conceptos/ConceptosEstimacionMobile";
import { CapacitorContext } from "../../context/CapacitorContext";

const EstimacionVersionResumen = ({ idEstimacionVersion }) => {
  const { conceptos, getConceptosEstimacionVersion } =
    useContext(ConceptosContext);
  const { platform } = useContext(CapacitorContext);
  const { clearModal } = useContext(ModalContext);
  const { idProyecto } = useProyecto();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstimacionVersion]);

  const fetchData = () => {
    getConceptosEstimacionVersion(idProyecto, idEstimacionVersion);
  };

  const handleCallback = () => {
    clearModal();
    fetchData();
  };

  const renderConceptos = () => {
    if (Array.isArray(conceptos)) {
      if (platform !== "web") {
        return (
          <ConceptosEstimacionMobile
            conceptos={conceptos}
            handleCallback={handleCallback}
            idEstimacionVersion={idEstimacionVersion}
          />
        );
      }
      return (
        <div className="row my-3 px-1">
          <ConceptosEstimacion
            conceptos={conceptos}
            idEstimacionVersion={idEstimacionVersion}
            handleCallback={handleCallback}
          />
        </div>
      );
    }
  };

  return (
    <div className="estimacion-resumen card px-md-4 px-3 py-3 shadow mb-3">
      <EstimacionVersionHeader
        title="HOJA RESUMEN GENERADOR"
        view="resumen"
        pages="3"
        page="2"
      />
      {renderConceptos()}
    </div>
  );
};

export default EstimacionVersionResumen;
