import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import useProyecto from "../../hooks/useProyecto";
import { formatMonto, getValue } from "../../utils";
import { PagosContext } from "../../context/PagosContext";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { ContratosContext } from "../../context/ContratosContext";
import ReactSwitch from "react-switch";
import { MontosContext } from "../../context/MontosContext";

const MontosSugeridosAnticipo = ({ anticipo, autorizado, sugerido }) => {
  return (
    <div>
      <label className="bold">Montos Sugeridos</label>
      <table className="table small">
        <tbody>
          <tr>
            <td className="bg-light border">Anticipo (%)</td>
            <td className="border">{anticipo}%</td>
          </tr>
          <tr>
            <td className="bg-light border">Monto Original Autorizado ($)</td>
            <td className="border">${formatMonto(autorizado)}</td>
          </tr>
          <tr>
            <td className="bg-light border">Anticipo Sugerido ($)</td>
            <td className="border">${formatMonto(sugerido)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const MontosSugeridosEstimacion = ({
  anticipo,
  estimado,
  autorizado,
  sugerido,
  contrato,
}) => {
  return (
    <div>
      <label className="bold">Montos Sugeridos</label>
      <table className="table small">
        <tbody>
          <tr>
            <td className="bg-light border">Anticipo (%)</td>
            <td className="border">{anticipo}%</td>
          </tr>
          <tr>
            <td className="bg-light border">Total Solicitado</td>
            <td className="border">${formatMonto(estimado)}</td>
          </tr>
          <tr>
            <td className="bg-light border">Total Autorizado</td>
            <td className="border">${formatMonto(autorizado)}</td>
          </tr>
          <tr>
            <td className="bg-light bold border">Sugerido</td>
            <td className="border bold">${formatMonto(sugerido)}</td>
          </tr>
          <tr>
            <td className="bg-light border">Anticipo Restante</td>
            <td className="border">
              ${formatMonto((contrato * parseFloat(anticipo)) / 100 - sugerido)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const PagoForm = ({ idProyecto, idContrato, handleCancel, handleCallback }) => {
  const { pago, pagos, postPagoContrato, setPropiedadPago } =
    useContext(PagosContext);
  const { montos, getMontosContrato } = useContext(MontosContext);
  const { contrato } = useContext(ContratosContext);
  const { estimaciones, getEstimacionesContrato } =
    useContext(EstimacionesContext);
  const { permiso } = useProyecto();

  const [isAnticipo, setIsAnticipo] = useState(false);
  const [sugerencia, setSugerencia] = useState(null);

  useEffect(() => {
    fetchEstimaciones();
    getMontosContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  useEffect(() => {
    if (contrato && contrato !== null && montos && montos !== null) {
      if (
        contrato !== null &&
        montos !== null &&
        isAnticipo &&
        contrato &&
        montos
      ) {
        if (contrato.anticipo !== null && sugerencia === null) {
          const montoSugerido =
            montos.autorizado * (parseFloat(contrato.anticipo) / 100);
          setPropiedadPago("monto", montoSugerido);
          setSugerencia(
            <MontosSugeridosAnticipo
              sugerido={montoSugerido}
              anticipo={contrato.anticipo}
              autorizado={montos.autorizado}
            />
          );
        }
      } else if (
        !pago.Estimacion &&
        pago.idEstimacion !== null &&
        contrato.anticipo !== null
      ) {
        if (sugerencia === null) {
          const currentEstimacion = estimaciones?.find(
            ({ idEstimacion }) => idEstimacion === parseInt(pago.idEstimacion)
          );
          if (currentEstimacion) {
            const { anticipo } = contrato;
            const { estimado, autorizado } = currentEstimacion;
            const montoSugerido = autorizado * (1 - parseFloat(anticipo) / 100);
            setPropiedadPago("monto", montoSugerido);
            setSugerencia(
              <MontosSugeridosEstimacion
                estimado={estimado}
                anticipo={anticipo}
                autorizado={autorizado}
                sugerido={montoSugerido}
                contrato={montos.autorizado}
              />
            );
          }
        }
      } else if (sugerencia !== null) setSugerencia(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnticipo, contrato, montos, pago]);

  function fetchEstimaciones() {
    getEstimacionesContrato(idProyecto, idContrato);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    postPagoContrato(idProyecto, idContrato, pago, handleCallback);
  };

  const renderAnticipo = () => {
    if (contrato.anticipo === null) {
      return (
        <p className="text-danger">
          Aún no has configurado el anticipo del contrato.
        </p>
      );
    }
    if (
      Array.isArray(pagos) &&
      pagos.length === 0 &&
      contrato.anticipo !== null
    ) {
      return (
        <div className="row align-items-center mb-3">
          <div className="col-6">
            <label>¿Es Anticipo?</label>
          </div>
          <div className="col-6 text-end">
            <ReactSwitch checked={isAnticipo} onChange={setIsAnticipo} />
          </div>
        </div>
      );
    }
  };

  const renderMonto = () => {
    if (isNaN(parseInt(pago.idPago))) {
      return (
        <div className="input-group mb-3">
          <span className="input-group-text">$</span>
          <input
            step=".01"
            type="number"
            className="form-control border"
            value={getValue(pago, "monto")}
            onChange={(e) => setPropiedadPago("monto", e.target.value)}
          />
        </div>
      );
    }
    return (
      <p>
        {"$"}
        {formatMonto(pago.monto)}
      </p>
    );
  };

  const renderAutorizacion = () => {
    if (isNaN(parseInt(pago.idPago))) {
      return (
        <input
          type="date"
          className="form-control"
          value={getValue(pago, "fecha_hora_autorizacion", "date")}
          onChange={(e) =>
            setPropiedadPago("fecha_hora_autorizacion", e.target.value)
          }
        />
      );
    }
    return <p>{moment(pago.fecha_hora_autorizacion).format("DD MMM YYYY")}</p>;
  };

  const renderLiberacion = () => {
    if (!isNaN(parseInt(pago.idPago))) {
      return (
        <div>
          <label>Fecha Liberado</label>
          {pago.fecha_hora_liberado === null ? (
            <input
              type="date"
              className="form-control"
              value={getValue(pago, "fecha_hora_liberado", "date")}
              onChange={(e) =>
                setPropiedadPago("fecha_hora_liberado", e.target.value)
              }
            />
          ) : (
            <p>{moment(pago.fecha_hora_liberado).format("DD MMM YYYY")}</p>
          )}
        </div>
      );
    }
  };

  const renderEstimacion = () => {
    if (isNaN(parseInt(pago.idPago)) && !isAnticipo) {
      return (
        <select
          value={getValue(pago, "idEstimacion")}
          className="form-control mb-3"
          onChange={(e) => setPropiedadPago("idEstimacion", e.target.value)}
        >
          <option>Seleccionar...</option>
          {estimaciones?.map((estimacion) => (
            <option
              key={estimacion.idEstimacion}
              value={estimacion.idEstimacion}
            >
              Estimación #{estimacion.numero} -{" "}
              {moment(estimacion.fecha_inicio).format("DD MMM YYYY")}
            </option>
          ))}
        </select>
      );
    }
    if (pago.Estimacion && pago.Estimacion !== null) {
      const estimacion = pago.Estimacion;
      return (
        <p>
          Estimación #{estimacion.numero} -{" "}
          {moment(estimacion.fecha_inicio).format("DD MMM YYYY")}
        </p>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="px-0">
      {renderAnticipo()}
      {!isAnticipo && <label>Estimación</label>}
      {renderEstimacion()}
      {sugerencia}
      <label>Monto</label>
      {renderMonto()}
      <div className="row mb-3">
        <div className="col-12 col-md-6">
          <label>Fecha Autorizado</label>
          {renderAutorizacion()}
        </div>
        <div className="col-12 col-md-6">{renderLiberacion()}</div>
      </div>

      <label>Concepto</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(pago, "comentario")}
        onChange={(e) => setPropiedadPago("comentario", e.target.value)}
      />
      <label>Comprobante</label>
      <input
        type="file"
        className="form-control"
        disabled={!["admin", "finanzas"].includes(permiso)}
      />
      <div className="row align-items-center mt-3">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn btn-primary w-100">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default PagoForm;
