import React, { useContext, useState } from "react";
import UploadSheet from "./UploadSheet";
import useProyecto from "../../hooks/useProyecto";
import { EstimacionVersionesContext } from "../../context/EstimacionVersionesContext";
import { useNavigate, useParams } from "react-router-dom";

const UploadEstimacionVersion = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const { spinner, saveEstimacionVersion } = useContext(
    EstimacionVersionesContext
  );
  const { idContrato, idEstimacion } = useParams();
  const { idProyecto } = useProyecto();

  const handleSubmit = () => {
    saveEstimacionVersion(idProyecto, idContrato, idEstimacion, file, handleCallback);
  };

  const handleCallback = (idEstimacionVersion) => {
    navigate(
      `/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${idEstimacion}/versiones/${idEstimacionVersion}`
    );
  };

  return (
    <div className="container-fluid">
      <h1 className="bold h3 mb-3">Cargar Versión de Estimación</h1>
      <UploadSheet file={file} setFile={setFile} />
      {file !== null && (
        <button
          disabled={spinner}
          onClick={handleSubmit}
          className="btn btn-primary"
        >
          {spinner ? (
            <div className="spinner-border" />
          ) : (
            <span>
              <i className="fa fa-upload" /> Subir Estimacion
            </span>
          )}
        </button>
      )}
    </div>
  );
};

export default UploadEstimacionVersion;
