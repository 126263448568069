import React, { useState, Fragment, useEffect, useContext } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { AppConfigContext } from "../../context/AppConfigContext";
import { DestajosContext } from "../../context/DestajosContext";
import { EditModeContext } from "../../context/EditModeContext";
import { AlertasContext } from "../../context/AlertasContext";
import { ModalContext } from "../../context/ModalContext";
import AlertaForm from "../alertas/AlertaForm";
import DestajoTabla from "./DestajoTabla";
import DestajoForm from "./DestajoForm";
import { getValue } from "../../utils";
import Rubro from "../common/Rubro";
import CheckBox from "./CheckBox";
import moment from "moment";
import AvancePickerMobile from "../cronograma/AvancePickerMobile";
import { PartidasContext } from "../../context/PartidasContext";

const DestajosContainer = SortableContainer((props) => props.renderDestajos());

const DestajoFila = SortableElement((props) => (
  <DestajoForm
    index={props.index}
    idProyecto={props.idProyecto}
    idPartida={props.idPartida}
    destajo={props.destajo}
    key={"destajo-" + props.destajo.idDestajo}
    setPropiedadDestajo={props.setPropiedadDestajo}
    deleteDestajo={props.deleteDestajo}
    confirm={props.confirm}
  />
));

const PartidaTabla = ({
  partida,
  proyecto,
  destajos,
  subdestajos,
  getProyecto,
  showDetails,
}) => {
  const [showDestajos, setShowDestajos] = useState(false);
  const [destajosPartida, setDestajosPartida] = useState([]);

  const { idProyecto, permiso } = proyecto;

  const {
    postDestajos,
    deleteDestajo,
    reordenarDestajos,
    createNuevoDestajo,
    setPropertyDestajo,
  } = useContext(DestajosContext);

  const appConfig = useContext(AppConfigContext);
  const { postAvancePartida } = useContext(PartidasContext);
  const { confirm, modalComponent } = useContext(ModalContext);

  const { level, editMode, viewLevel, editModeOn } =
    useContext(EditModeContext);

  const { createAlerta, setPropertyAlerta } = useContext(AlertasContext);

  useEffect(() => {
    if (Array.isArray(destajos)) {
      handleDestajosPartida();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destajos]);

  function canEditProgress() {
    return ["admin", "supervisor"].includes(permiso);
  }

  function handleDestajosPartida() {
    let current = destajos
      .filter((destajo) => destajo.idPartida === partida.idPartida)
      .sort((a, b) => (a.orden > b.orden ? 1 : -1));
    setDestajosPartida(current);
  }

  function handleAlerta() {
    createAlerta();
    setPropertyAlerta("idPartida", partida.idPartida);
    modalComponent("Agregar Alerta", <AlertaForm />);
  }

  function toggleDestajos() {
    setShowDestajos(!showDestajos);
    if (!showDestajos) viewLevel("destajos");
    else viewLevel("partidas");
  }

  function handleReorder(props) {
    reordenarDestajos(partida.idPartida, props);
  }

  function handleCreateDestajo() {
    createNuevoDestajo(partida.idPartida, destajosPartida.length);
  }

  function handlePostDestajos() {
    postDestajos(idProyecto, destajosPartida, getProyecto);
  }


  function postAvance (progreso) {
    console.log(progreso)
    postAvancePartida(
      partida.idPartida,
      progreso > partida.generalPartida,
      idProyecto,
      getProyecto
    );
  };

  function renderDestajos() {
    if (showDestajos) {
      let destajos = destajosPartida.filter(
        (destajo) => String(destajo.idDestajo)[0] !== "d"
      );
      if (editMode === "part-" + partida.idPartida)
        return destajos.map((destajo, index) => {
          return (
            <DestajoFila
              index={index}
              confirm={confirm}
              destajo={destajo}
              idProyecto={idProyecto}
              idPartida={partida.idPartida}
              deleteDestajo={deleteDestajo}
              key={"destajo-" + destajo.idDestajo}
              setPropiedadDestajo={setPropertyDestajo}
            />
          );
        });
      else
        return destajos.map((destajo) => {
          return (
            <DestajoTabla
              key={"destajo-" + destajo.idDestajo}
              idPartida={partida.idPartida}
              subdestajos={subdestajos}
              getProyecto={getProyecto}
              idProyecto={idProyecto}
              editModeOn={editModeOn}
              viewLevel={viewLevel}
              editMode={editMode}
              confirm={confirm}
              permiso={permiso}
              destajo={destajo}
              level={level}
            />
          );
        });
    }
  }

  function renderEditbutton() {
    let components = [];
    if (showDestajos && ["admin", "coordinador"].includes(permiso)) {
      if (editMode === "part-" + partida.idPartida)
        components = [
          <button
            className="btn btn-primary me-2"
            onClick={handlePostDestajos}
            key={"save-destajos-" + partida.idPartida + "-btn"}
          >
            Guardar
          </button>,
          <button
            className="btn btn-outline-primary"
            key={"add-destajos-" + partida.idPartida + "-btn"}
            onClick={handleCreateDestajo}
          >
            <i className="fa fa-plus me-2"></i>
            Agregar Destajo
          </button>,
          <button
            key={"cancelPartida-" + partida.idPartida}
            className="btn btn-outline-danger ms-2"
            onClick={() =>
              confirm(
                "¿Está seguro que desea cancelar? Los cambios a los Destajos de la Partida " +
                  partida.nombre +
                  " no se guardarán.",
                getProyecto
              )
            }
          >
            Cancelar
          </button>,
        ];
      else if (!editMode) {
        components = [
          <button
            className="btn btn-outline-primary"
            onClick={() => editModeOn(`part-${partida.idPartida}`)}
            key={"edit-destajos-" + partida.idPartida + "-btn"}
          >
            <i className="fa fa-edit me-2"></i>
            Editar Destajos de {partida.nombre}
          </button>,
        ];
      }
      return (
        <tr className="border-primary border-bottom bg-primary-light">
          <td />
          <td className="ps-2" style={{ minWidth: 330 }} colSpan={6}>
            {components}
          </td>
        </tr>
      );
    }
  }

  const renderProgress = () => {
    let progreso = partida.real * 100;
    if (getValue(appConfig, "progress") === "money") {
      progreso = parseInt(partida.progreso);
    } else if(getValue(appConfig, "avance") === "partida") {
      if(canEditProgress()) {
        return (
          <AvancePickerMobile
            progreso={parseInt(partida.generalPartida)}
            setCantidad={postAvance}
            intervalo={10}
            limite={100}
          />
        )
      }
      
    }
    if (isNaN(parseInt(progreso))) return "-";
    return `${parseInt(progreso)}%`;
  };

  let { nombre, ponderacion, punto_critico, real } = partida;
  return (
    <Fragment>
      <tr
        className={`align-items-center ${
          showDestajos
            ? "bg-primary-light border-top border-primary"
            : "border-bottom"
        }`}
      >
        <td className="ponderacion">
          <p className="h5 mb-0 bold">
            {ponderacion !== null && !isNaN(parseFloat(ponderacion)) ? parseFloat(ponderacion) : "-"}%
          </p>
        </td>
        <td className="rubro">
          <div className="row mx-0">
            <div className="col-2 px-0">
              <div
                className={`btn btn-sm btn-${
                  showDestajos ? "primary" : "light"
                } border`}
                onClick={toggleDestajos}
              >
                <i
                  className={`fas fa-chevron-${
                    showDestajos ? "up" : "down"
                  } toggle`}
                />
              </div>
            </div>
            <div className="col-10 px-0">
              <Rubro
                completado={real === 1}
                nombre={nombre}
                className="bold h4"
              />
            </div>
          </div>
        </td>
        <td
          className="text-center bold h5"
          style={{ width: 180, minWidth: 180 }}
        >
          {renderProgress()}
        </td>
        <td className="text-center">
          <CheckBox checked={punto_critico} disabled />
        </td>
        <td>{moment(getValue(partida, "fecha_inicio")).format("DD MMM YYYY")}</td>
        <td>{moment(getValue(partida, "fecha_fin")).format("DD MMM YYYY")}</td>
        <td className="buttons">
          <button
            className="btn btn-sm btn-light border me-2"
            onClick={() => showDetails(partida)}
          >
            <i className="fa fa-info mx-1"></i>
          </button>
          {getValue(appConfig, "alerts_enabled", "boolean") && (
            <button
              className="btn btn-sm btn-light border"
              onClick={handleAlerta}
            >
              <i className="fa fa-bell"></i>
            </button>
          )}
        </td>
      </tr>
      {showDestajos && (
        <DestajosContainer
          renderDestajos={renderDestajos}
          toggleDestajos={toggleDestajos}
          onSortEnd={handleReorder}
          showDestajos={showDestajos}
          pressDelay={100}
          useDragHandle
        />
      )}
      {renderEditbutton()}
    </Fragment>
  );
};

export default PartidaTabla;
