import React, { useContext, useEffect } from "react";
import { EntradasContext } from "../../context/EntradasContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import Entradas from "../bitacora/Entradas";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import useProyecto from "../../hooks/useProyecto";
import { ConceptosContext } from "../../context/ConceptosContext";
import { CapacitorContext } from "../../context/CapacitorContext";

const BitacoraConcepto = ({
  idFolder,
  idConcepto,
  idConceptoUbicacion,
  idEstimacionVersion,
}) => {
  const { idProyecto } = useProyecto();

  const { platform } = useContext(CapacitorContext);
  const { concepto } = useContext(ConceptosContext);

  const { max, entradas, postEntrada, getEntradas, clearBitacora } =
    useContext(EntradasContext);

  const { uploadSingleAdjunto } = useContext(AdjuntosContext);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    clearBitacora();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idConceptoUbicacion]);

  useEffect(() => {
    if (concepto && concepto !== null) {
      fetchEntradas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concepto]);

  const fetchEntradas = async () => {
    const params = {};
    if (concepto && concepto !== null) {
      params.idConcepto = concepto.idConcepto;
    } else if (idConcepto) {
      params.idConcepto = idConcepto;
    }

    if (idConceptoUbicacion) {
      params.idConceptoUbicacion = idConceptoUbicacion;
    } else if (idEstimacionVersion) {
      params.idEstimacionVersion = idEstimacionVersion;
    }

    getEntradas(idProyecto, 0, 25, params);
  };

  const handleSubmit = async (message, adjuntos) => {
    const entradaData = {
      message,
    };
    if (concepto && concepto !== null) {
      entradaData.idConcepto = concepto.idConcepto;
    } else if (idConcepto) {
      entradaData.idConcepto = idConcepto;
    }
    if (idConceptoUbicacion) {
      entradaData.idConceptoUbicacion = idConceptoUbicacion;
    } else if (idEstimacionVersion) {
      entradaData.idEstimacionVersion = idEstimacionVersion;
    }
    if (adjuntos.length > 0) {
      adjuntos.forEach(async (file) => {
        const data = await uploadSingleAdjunto(idProyecto, idFolder, file);
        entradaData.idAdjunto = data.idAdjunto;
        await postEntrada(idProyecto, entradaData, fetchEntradas);
      });
    } else {
      postEntrada(idProyecto, entradaData, fetchEntradas);
    }
  };

  return (
    <div
      className="position-relative bg-light pb-5"
      style={{
        height: platform === "web" ? "70vh" : "auto",
      }}
    >
      <div
        className="row bg-light px-4 py-3"
        style={{
          height: "calc(100% - 52px)",
        }}
      >
        <Entradas
          scrollCallback={fetchEntradas}
          idUsuario={user.idUsuario}
          idProyecto={idProyecto}
          entradas={entradas}
          user={user}
          max={max}
        />
      </div>
      <BitacoraInput modifier={handleSubmit} />
    </div>
  );
};

export default BitacoraConcepto;
