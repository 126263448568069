import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";
import EstimacionForm from "../finanzas/EstimacionForm";
import { ModalContext } from "../../context/ModalContext";
import SingleEstimacionHeader from "./SingleEstimacionHeader";
import ConceptosEstimacion from "../conceptos/ConceptosEstimacion";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import ConceptosEstimacionMobile from "../conceptos/ConceptosEstimacionMobile";
import StatusBadge from "../common/StatusBadge";

const SingleEstimacion = () => {
  const { estimacion, editEstimacion, selectEstimacion } =
    useContext(EstimacionesContext);
  const { modalComponent } = useContext(ModalContext);
  const { platform } = useContext(CapacitorContext);
  const { idContrato } = usePreciario();
  const { idProyecto } = useProyecto();

  const versionesURL = `/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${estimacion.idEstimacion}/versiones`;

  function handleCallback() {
    selectEstimacion(idProyecto, idContrato, estimacion.idEstimacion);
  }

  function handleEdit() {
    editEstimacion(estimacion);
    modalComponent(
      "Editar Estimacion",
      <EstimacionForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCallback={handleCallback}
      />
    );
  }

  function renderVersion() {
    if (estimacion.EstimacionVersion !== null) {
      const { idEstimacionVersion, estado, version } =
        estimacion.EstimacionVersion;
      return (
        <>
          <div className="row mb-3 mt-4">
            <div className="col-6">
              <div className="row align-items-center">
                <div className="col-6">
                  <div
                    className="h5" 
                  >
                    Versión #{version}{" "}
                    <Link to={`${versionesURL}/${idEstimacionVersion}`} className="btn btn-sm border ms-2 btn-light d-inline-block">
                      <i className="fa fa-eye me-2" />Ver Detalle
                    </Link>
                  </div>
                  <StatusBadge text={estado} />
                </div>
              </div>
            </div>
            <div className="col-6 text-end">
              <Link
                className="btn btn-outline-dark border"
                to={versionesURL}
                key="versiones"
              >
                <i className="fa fa-tasks me-1" />
                Versiones
              </Link>
            </div>
          </div>
          {platform !== "web" ? (
            <div className="px-1">
              <ConceptosEstimacionMobile conceptos={estimacion.conceptos} />
            </div>
          ) : (
            <ConceptosEstimacion conceptos={estimacion.conceptos} />
          )}
        </>
      );
    }
    return (
      <div>
        <p>Esta estimación aún no tiene versiones cargadas en Arqvisor.</p>
        <Link to={`${versionesURL}/subir`} className="btn btn-primary">
          <i className="fa fa-upload me-1" />
          Subir Versión
        </Link>
      </div>
    );
  }

  return (
    <div>
      <div className="row mb-2 align-items-center">
        <div className="col-8">
          <h3 className="mb-0">Estimación #{estimacion.numero}</h3>
        </div>
        <div className="col-4 text-end">
          <button
            key="edit"
            onClick={handleEdit}
            className="btn btn-outline-primary"
          >
            <i className="fa fa-edit" />
            <span className="hide-mobile ms-1">Editar</span>
          </button>
        </div>
      </div>
      <SingleEstimacionHeader />
      {renderVersion()}
    </div>
  );
};

export default SingleEstimacion;
