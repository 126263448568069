import React, { useContext } from "react";
import { UserDevicesContext } from "../../context/UserDevicesContext";
import { getValue } from "../../utils";

const DeviceForm = () => {
  const { user_device, saveUserDevice, setPropertyUserDevice } =
    useContext(UserDevicesContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUserDevice(user_device);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Name</label>
      <input
        type="text"
        className="form-control"
        value={getValue(user_device, "name")}
        onChange={(e) => setPropertyUserDevice("name", e.target.value)}
      />
      <button type="submit" className="btn btn-primary w-100 my-3">
        Save Device
      </button>
      <button type="button" className="btn text-muted w-100 my-3">
        Cancel
      </button>
    </form>
  );
};

export default DeviceForm;
