import React, { useContext, useEffect } from "react";
import { ProyectosContext } from "../context/ProyectosContext";
import { MenuContext } from "../context/MenuContext";
import Folder from "./Folder";
import useProyecto from "../hooks/useProyecto";
import { useParams } from "react-router-dom";

const Supervision = () => {
  const { idFolder } = useParams();
  const { idProyecto } = useProyecto();
  const { setSelected } = useContext(MenuContext);
  const { getSingleProyecto } = useContext(ProyectosContext);

  useEffect(() => {
    getSingleProyecto(idProyecto);
    setSelected({ title: "supervision" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  return (
    <Folder
      title="Supervisión"
      idFolder={idFolder || "supervision"}
    />
  );
};

export default Supervision;
