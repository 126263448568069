import React from "react";
import Table from "../common/Table";
import UsuarioFila from "./UsuarioFila";
import InfoCard from "../common/InfoCard";

const ListaUsuarios = ({
  query,
  users,
  platform,
  isTableView,
  editUsuario,
  deleteUsuario,
}) => {

  const renderUsers = () => {
    if (Array.isArray(users)) {
      if (users.length === 0) {
        return <p>Aún no has agregado usuarios.</p>;
      }

      return users.map((user) => (
        <InfoCard
          usuario={user}
          platform={platform}
          key={user.idUsuario}
          editUsuario={editUsuario}
          deleteUsuario={deleteUsuario}
        />
      ));
    }
  };

  const renderTableColumns = () => {
    return (
      <>
        <th className="col-5 py-4 ps-5">Usuario</th>
        <th className="col-1 py-4 ps-2">Foto</th>
        <th className="col-3 py-4 ps-1">Correo</th>
        <th className="col-2 py-4 ps-1">Permiso</th>
        <th className="col-2 py-4 ps-1">Acciones</th>
      </>
    )
  }

  const renderUsersRows = () => {
    return (
      users?.map(item => {
        return (
          <UsuarioFila
            key={`${item.idUsuario}-${item.idProyecto}`}
            usuario={item}
            editUsuario={editUsuario}
            deleteUsuario={deleteUsuario}
          />
        )
      })
    )
  }

  const renderContent = () => {
    if (isTableView) {
      return (
        <Table
          data={users}
          query={query}
          renderItems={renderUsersRows}
          renderColumns={renderTableColumns}
        />
      );
    }

    return (
      <div className={`row mx-auto w-100 pb-4 ${platform === 'web' && 'mt-3'}`}>
        {renderUsers()}
      </div>
    )
  };

  return renderContent();
};

export default ListaUsuarios;
