import api from "./api";

const route = "/avances";

export default {
  getUltimoAvance: (idProyecto) => api.get(`${route}/${idProyecto}/ultimo`),
  postAvance: (idProyecto, data) =>
    api.post(`${route}/${idProyecto}`, { ...data }),
  postAvancePartida: (idProyecto, data) =>
    api.post(`${route}/${idProyecto}/partida`, { ...data }),
};
