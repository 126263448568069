import React, { useContext } from "react";
import { FolderContext } from "../../context/FolderContext";

const Breadcrumb = ({ idProyecto }) => {
  const { folder, breadcrumb, getFolder, clearFolder, popBreadcrumb } =
    useContext(FolderContext);

    
  const renderElements = () => {
    if (breadcrumb.length > 1) {
      return breadcrumb.map((folder, index) =>{
        const lastItem = index + 1 >= breadcrumb.length;
        return (
          <span className="d-flex p-0 me-2 align-items-end" style={{width: 'max-content'}} key={folder.idFolder}>
            {index > 0 && (
              <i style={{fontSize: '1.3rem'}} className="fas fa-chevron-right text-primary me-2"/>
            )}
            <div
              className={` ${lastItem ? 'd-inline-block bold' : 'btn btn-link text-secondary py-0'}  text-capitalize 0 pr-0`}
              key={folder.idFolder}
              style={{
                fontSize: '1rem',
                lineHeight: '1.25'
              }}
              onClick={() => {
                if(!lastItem) {
                  clearFolder();
                  popBreadcrumb();
                  getFolder(idProyecto, folder.idFolder);
                }
              }}
            >
              {folder.nombre}
            </div>
          </span>
        )
      });
    }
    return <span className="text-capitalize bold" style={{fontSize: '20px'}}>{folder?.nombre || 'Folder'}</span>
  };

  return (
    <div className="row align-items-center">
      {renderElements()}
    </div>
  )
};

export default Breadcrumb;
