import React from "react";
import { formatMonto } from "../../utils";
import usePreciario from "../../hooks/usePreciario";
import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { informationCircle, chatbubbleEllipsesOutline } from "ionicons/icons";

const ConceptoEstimacionMobile = ({ concepto }) => {
  const { showConceptoEstimacionDetail, handleComments } = usePreciario();

  const renderCantidad = () => {
    const { autorizado, supervisado, estimado } = concepto;
    if (autorizado && autorizado !== null) {
      return autorizado;
    }
    if (supervisado && supervisado !== null) {
      return supervisado;
    }
    return estimado;
  };

  const renderPrecio = () => {
    const { precio_autorizado, precio } = concepto;
    if (concepto.extra) {
      return precio_autorizado;
    }
    return precio;
  };

  return (
    <IonItem>
      <IonLabel>
        <span className="small bold">
          {"["}
          {concepto.clave}
          {"]"} {concepto.nombre}
        </span>
        <div className="row small">
          <div className="col-4">
            {formatMonto(renderCantidad())} {concepto.unidad}
          </div>
          <div className="col-4">${formatMonto(renderPrecio())}</div>
          <div className="col-4">
            ${formatMonto(concepto.importe_autorizado)}
          </div>
        </div>
      </IonLabel>
      <IonIcon
        onClick={() => handleComments(concepto)}
        icon={chatbubbleEllipsesOutline}
        color="primary"
        slot="end"
      />
      <IonIcon
        onClick={() => showConceptoEstimacionDetail(concepto)}
        icon={informationCircle}
        color="primary"
        slot="end"
      />
    </IonItem>
  );
};

const ConceptosEstimacionMobile = ({ conceptos, marginRight }) => {
  const getStyle = () => {
    if (marginRight && marginRight !== null) {
      return { marginRight };
    }
  };

  const marginAdjust = "-8px";

  return (
    <>
      <div style={{ marginLeft: marginAdjust, marginRight: marginAdjust }}>
        <div className="row bg-light bold border py-2 mx-0">
          <div className="col-8">Conceptos</div>
          <div className="col-4">Acciones</div>
        </div>
      </div>
      <div className="row" style={getStyle()}>
        <IonList
          className="px-0"
          id="conceptos-estimacion-mobile"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          {conceptos.map((concepto) => (
            <ConceptoEstimacionMobile
              key={concepto.idConcepto}
              concepto={concepto}
            />
          ))}
        </IonList>
      </div>
    </>
  );
};

export default ConceptosEstimacionMobile;
