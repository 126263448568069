import React, { useContext } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { formatMonto } from "../../utils";
import moment from "moment";

const SingleEstimacionHeader = () => {
  const { estimacion } = useContext(EstimacionesContext);

  return (
    <div className="row mb-3">
      <div className="col-12 col-md-4">
        <table className="table mb-md-0 border">
          <tbody>
            <tr className="border bg-primary text-white">
              <td colSpan={2}>MONTOS</td>
            </tr>
            <tr>
              <td className="bg-light bold">IMPORTE SOLICITADO</td>
              <td>${formatMonto(estimacion.estimado)}</td>
            </tr>
            <tr>
              <td className="bg-light bold">IMPORTE AUTORIZADO</td>
              <td>${formatMonto(estimacion.autorizado)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-4">
        <table className="table mb-md-0 border">
          <tbody>
            <tr className="border bg-primary text-white">
              <td colSpan={2}>PERIODO DE EJECUCION</td>
            </tr>
            <tr>
              <td style={{ width: 25 }}>DE:</td>
              <td>{moment(estimacion?.fecha_inicio).format("DD MMM YYYY")}</td>
            </tr>
            <tr>
              <td style={{ width: 25 }}>AL:</td>
              <td>{moment(estimacion?.fecha_fin).format("DD MMM YYYY")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-4">
        <table className="table mb-md-0 border">
          <tbody>
            <tr className="border bg-primary text-white">
              <td colSpan={4}>FECHA</td>
            </tr>
            <tr>
              <td colSpan={4}>
                {moment(estimacion?.fecha_ultima_version).format("DD MMM YYYY")}
              </td>
            </tr>
            <tr>
              <td>PAQUETE NO.:</td>
              <td>{estimacion?.numero}</td>
              <td>TIPO:</td>
              <td className="text-uppercase">
                {estimacion?.tipo === null ? "normal" : estimacion.tipo}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SingleEstimacionHeader;
