import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useProyecto from "../hooks/useProyecto";
import PillTabs from "../components/common/PillTabs";
import OffCanvas from "../components/global/OffCanvas";
import { ModalContext } from "../context/ModalContext";
import StatusBadge from "../components/common/StatusBadge";
import { ContratosContext } from "../context/ContratosContext";
import { EstimacionesContext } from "../context/EstimacionesContext";
import { EstimacionVersionesContext } from "../context/EstimacionVersionesContext";
import EstimacionVersionPortada from "../components/estimacionesversiones/EstimacionVersionPortada";
import EstimacionVersionResumen from "../components/estimacionesversiones/EstimacionVersionResumen";
import EstimacionVersionConcepto from "../components/estimacionesversiones/EstimacionVersionConcepto";
import EstimacionVersionCierreForm from "../components/estimacionesversiones/EstimacionVersionCierreForm";
import { CambioEstimacionVersionesContext } from "../context/CambioEstimacionVersionsContext";
import HistorialEstimacionVersion from "./HistorialEstimacionVersion";
import { MenuContext } from "../context/MenuContext";
import { CapacitorContext } from "../context/CapacitorContext";

const SingleEstimacionVersion = () => {
  const [view, setView] = useState("portada");
  const [showHistory, setShowHistory] = useState(false);

  const { idProyecto, permiso } = useProyecto();

  const { platform } = useContext(CapacitorContext);
  const { setSelected, setButtons } = useContext(MenuContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { estimacion, selectEstimacion } = useContext(EstimacionesContext);

  const { idContrato, idEstimacion, idEstimacionVersion } = useParams();
  const {
    estimacionversion,
    downloadEstimacionVersion,
    getSingleEstimacionVersion,
  } = useContext(EstimacionVersionesContext);
  const { getCambioEstimacionVersiones } = useContext(
    CambioEstimacionVersionesContext
  );
  const { selectContrato } = useContext(ContratosContext);

  useEffect(() => {
    selectContrato(idProyecto, idContrato);
    selectEstimacion(idProyecto, idContrato, idEstimacion);
    getSingleEstimacionVersion(idProyecto, idContrato, idEstimacionVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (estimacion && estimacion !== null && platform !== "web") {
      setButtons(renderVersionButtons());
      setSelected({
        title: (
          <span>
            Estimacion #{estimacion?.numero}
            <span className="d-block">
              Version #{estimacionversion?.version}
            </span>
          </span>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimacion]);

  const handleDownload = () => {
    downloadEstimacionVersion(idProyecto, idEstimacionVersion);
  };

  const handleCierre = () => {
    modalComponent(
      "Cerrar Versión",
      <EstimacionVersionCierreForm
        handleCancel={clearModal}
        handleCallback={handleCallback}
      />
    );
  };

  const handleCallback = () => {
    clearModal();
    getCambioEstimacionVersiones(idProyecto, idEstimacionVersion);
    getSingleEstimacionVersion(idProyecto, idContrato, idEstimacionVersion);
  };

  const renderView = () => {
    if (view === "portada") {
      return (
        <div className="card px-md-3 px-2 py-3">
          <EstimacionVersionPortada idEstimacionVersion={idEstimacionVersion} />
        </div>
      );
    }
    if (view === "generadores") {
      return <EstimacionVersionConcepto />;
    }
    return (
      <EstimacionVersionResumen idEstimacionVersion={idEstimacionVersion} />
    );
  };

  const renderButton = () => {
    if (estimacionversion && estimacionversion !== null) {
      const { estado } = estimacionversion;
      if (
        (permiso === "supervisor" && estado === "enviada") ||
        (permiso === "contratista" &&
          ["creada", "revisada", "autorizada"].includes(estado)) ||
        (permiso === "obras" &&
          [
            "conciliada",
            "conciliada-sin-supervisor",
            "conciliada-sin-contratista",
          ].includes(estado)) ||
        (permiso === "gerencia" &&
          [
            "conciliada-con-obras",
            "concliada-con-obras-sin-contratista",
          ].includes(estado)) ||
        (permiso === "finanzas" &&
          [
            "conciliada-con-obras",
            "conciliada-con-obras-sin-contratista",
          ].includes(estado) &&
          estimacion?.tipo === "extra") ||
        (permiso === "contratista" && estado === "autorizada-control")
      ) {
        return (
          <button
            onClick={handleCierre}
            className="btn btn-sm btn-outline-primary me-2"
          >
            <i className="fas fa-envelope-open-text me-1"></i> Cerrar
          </button>
        );
      }
    }
  };

  const renderVersionButtons = () => {
    return (
      <>
        {renderButton()}
        <button
          className="btn btn-sm btn-outline-dark me-2"
          onClick={() => setShowHistory(!showHistory)}
        >
          <i className="fas fa-history" />
        </button>
        <button
          className="btn btn-sm btn-outline-dark"
          onClick={handleDownload}
        >
          <i className="fas fa-download" />
        </button>
      </>
    );
  };

  const renderVersion = () => {
    if (estimacionversion && estimacionversion !== null) {
      return (
        <div>
          <div className="row align-items-center">
            <div className="hide-mobile col-12 col-md-6 col-xl-9 mb-3">
              <h1 className="h4">
                Estimacion #{estimacion?.numero} - Version #
                {estimacionversion?.version}
              </h1>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mb-2 text-md-end">
              <div className="container-fluid px-0 mb-3">
                <StatusBadge fluid text={estimacionversion.estado} />
              </div>
              <div className="hide-mobile">{renderVersionButtons()}</div>
            </div>
          </div>
          <div className="card p-3 px-4 mb-3 shadow-sm">
            <PillTabs
              value={view}
              modifier={setView}
              tabs={[
                {
                  label: "Portada",
                  value: "portada",
                },
                {
                  label: "Resumen",
                  value: "resumen",
                },
                {
                  label: "Generadores",
                  value: "generadores",
                },
              ]}
            />
          </div>
          {renderView()}
          <OffCanvas
            showOffCanvas={showHistory}
            setShowOffCanvas={setShowHistory}
          >
            <HistorialEstimacionVersion
              idEstimacionVersion={idEstimacionVersion}
              idEstimacion={idEstimacion}
              idContrato={idContrato}
            />
          </OffCanvas>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-1 px-md-auto">{renderVersion()}</div>
  );
};

export default SingleEstimacionVersion;
