import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import EstadoObra from "../common/EstadoObra";
import { getEstadoProyecto, getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CapacitorContext } from "../../context/CapacitorContext";

const ObraCard = ({
  user,
  idCliente,
  proyecto,
  options,
  handleEdit,
  viewOptions,
  hideOptions,
  handleDelete,
}) => {
  const appconfig = useContext(AppConfigContext);
  const { platform } = useContext(CapacitorContext);

  const toggleOptions = (e) => {
    e.stopPropagation();
    if (!options) viewOptions(proyecto);
    else hideOptions();
  };
  const getEstado = () => {
    if (getValue(appconfig, "progress") === "money") {
      proyecto.real =
        proyecto.ejecutado !== null
          ? parseFloat(proyecto.ejecutado) / parseFloat(proyecto.autorizado)
          : 0;
    }
    return getEstadoProyecto(proyecto);
  };

  const renderProgress = () => {
    if (proyecto) {
      if (proyecto.programado) {
        let { programado, real, generalReal } = proyecto;
        programado = parseFloat(programado) * 100;
        real = getValue(appconfig, "avance") === "partida" ? parseFloat(generalReal) : parseFloat(real) * 100;
        if (
          getValue(appconfig, "progress") === "money" &&
          proyecto.ejecutado !== null &&
          proyecto.autorizado !== null
        ) {
          real =
            (parseFloat(proyecto.ejecutado) / parseFloat(proyecto.autorizado)) *
            100;
        }
        return (
          <Chart
            type="radialBar"
            options={{
              stroke: {
                lineCap: "round",
              },
              colors: ["#89d405", "#007991"],
              labels: ["Real", "Programado"],
              fill: {
                type: "gradient",
                gradient: {
                  shade: "dark",
                  stops: [0, 100],
                  gradientToColors: ["#B3FF00", "#05D4C6"],
                },
              },
              dataLabels: {
                formatter: function (val, opts) {
                  return parseFloat(val).toFixed(2);
                },
              },
              plotOptions: {
                radialBar: {
                  track: {
                    background: [
                      programado === 100 ? "#FC6767" : "#ddd",
                      "#ddd",
                    ],
                  },
                },
              },
            }}
            series={[real, programado]}
          />
        );
      }
    }
  };

  const renderOptions = () => {
    if (options) {
      if (options.idProyecto === proyecto.idProyecto) {
        if (idCliente) {
          return (
            <div className="card p-2 shadow options-container custom-modal">
              <button
                className="btn btn-link text-start text-danger"
                onClick={() => handleDelete(proyecto)}
              >
                <i className="fa fa-times me-2"></i> Quitar Obra
              </button>
            </div>
          );
        }
        return (
          <div className="card p-2 shadow options-container custom-modal">
            <button
              variant="link"
              className="btn text-start text-secondary"
              onClick={() => handleEdit(proyecto)}
            >
              <i className="fa fa-edit me-1"></i> Editar Obra
            </button>
            <button
              className="btn text-start text-danger"
              onClick={() => handleDelete(proyecto)}
            >
              <i className="fa fa-trash me-2"></i> Eliminar Obra
            </button>
          </div>
        );
      }
    }
  };

  const renderOptionsButton = () => {
    if (
      (["admin", "coordinador"].includes(proyecto.permiso) ||
        user.permiso === "admin") &&
      platform === "web"
    ) {
      return (
        <button
          className="btn btn-link text-secondary btn-options"
          onClick={toggleOptions}
        >
          <i className="fas fa-ellipsis-v" />
        </button>
      );
    }
  };

  return (
    <div className="card obra-card w-100 px-3 py-4 mb-3 shadow">
      {renderOptionsButton()}
      {renderOptions()}
      <div className="row align-items-center h-100 mx-0">
        <div className="col-4 px-0 h-100">
          <div className="chart-wrapper"> {renderProgress()}</div>
        </div>
        <div className="col-8">
          <h3 className="bold h5 text-overflow-ellipsis">{proyecto.nombre}</h3>
          <div className="row px-1 mb-3 align-items-center">
            <div className="col-8">
              <EstadoObra estado={getEstado()} />
            </div>
          </div>
          <Link
            to={`/obra/${proyecto.idProyecto}`}
            className="btn btn-primary w-100"
          >
            Ver Proyecto
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ObraCard;
