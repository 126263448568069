import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const FilaTotales = ({ conceptos, showAcumulados, showEstimaciones }) => {
  const appconfig = useContext(AppConfigContext);

  function getTotal(key, formatted) {
    let total = 0;
    if (Array.isArray(conceptos)) {
      conceptos.forEach((concepto) => {
        total += parseFloat(concepto[key]) || 0;
      });
    }
    if (isNaN(total)) return 0;
    if(formatted) return formatMonto(total);
    return total;
  }

  return (
    <tr className="bg-light bold">
      <td colSpan={5} className="text-uppercase">
        Totales
      </td>
      <td>${getTotal("importe", true)}</td>
      {showAcumulados && (
        <>
          <td>${getTotal("acumulado_estimado", true)}</td>
          <td>${getTotal("acumulado_autorizado", true)}</td>
          <td>{formatMonto(getTotal("acumulado_autorizado") / getTotal("importe") * 100)}%</td>
        </>
      )}
      {showEstimaciones && (
        <>
          <td className="ps-3">{getTotal("estimado")}</td>
          {getValue(appconfig, "supervise_estimates", "boolean") && (
            <td>{getTotal("supervisado")}</td>
          )}
          {getValue(appconfig, "authorize_estimates", "boolean") && (
            <td>{getTotal("autorizado")}</td>
          )}
          <td>${formatMonto(getTotal("importe_autorizado"))}</td>
          <td colSpan={6} />
        </>
      )}
    </tr>
  );
};

export default FilaTotales;
