import React, { useContext, useEffect, useState } from "react";
import { FILES_ENDPOINT, hideAdjuntoView, showAdjuntoView } from "../../utils";
import { EntradasContext } from "../../context/EntradasContext";
import { AdjuntosContext } from "../../context/AdjuntosContext";
import { AuthContext } from "../../context/AuthContext";
import BitacoraInput from "../bitacora/BitacoraInput";
import useProyecto from "../../hooks/useProyecto";
import { Document, Page, pdfjs } from "react-pdf";
import Entradas from "../bitacora/Entradas";
import moment from "moment";
import "./adjuntoView.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AdjuntoView = () => {
  const [src, setSrc] = useState("");
  const [scoped, setScoped] = useState(false);
  const [commentsEnabled, setCommentsEnabled] = useState(false);
  const [pdfScale, setPdfScale] = useState(1);

  const { idProyecto, proyecto } = useProyecto();

  const { adjunto, clearSingleAdjunto, downloadAdjunto, getAdjuntoProyecto } =
    useContext(AdjuntosContext);

  const { user } = useContext(AuthContext);

  const { max, entradas, postEntrada, getEntradas, resetEntradas } =
    useContext(EntradasContext);

  useEffect(() => {
    return () => {
      setCommentsEnabled(false);
    };
  }, []);

  useEffect(() => {
    if (commentsEnabled && adjunto !== null && proyecto !== null) {
      resetEntradas();
      fetchEntradas(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsEnabled, proyecto, adjunto]);

  useEffect(() => {
    if (adjunto !== null && proyecto !== null) {
      setSrc("");
      showAdjuntoView();
      getAdjuntoProyecto(
        proyecto.idProyecto,
        adjunto.idAdjunto,
        handleCallback
      );
    } else if (adjunto === null) {
      hideAdjuntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjunto]);

  const fetchEntradas = (offset) => {
    if (adjunto !== null) {
      getEntradas(idProyecto, offset, 25, { idAdjunto: adjunto?.idAdjunto });
    }
  };

  const handleCallback = (response) => {
    if (response !== null) {
      return setSrc(response);
    }
    setScoped(true);
    setSrc(`${FILES_ENDPOINT}/${adjunto.idAdjunto}.${adjunto.tipo}`);
  };

  const handleDownload = () => {
    if (!scoped) {
      return downloadAdjunto(proyecto.idProyecto, adjunto);
    }
    const link = document.createElement("a");
    link.href = src;
    link.download = true;
    document.body.append(link);
    link.click();
  };

  const renderView = () => {
    if (adjunto !== null) {
      let tipo = adjunto.tipo;
      if (tipo) tipo = String(tipo).toLowerCase();
      if (tipo === "pdf") {
        return (
          <>
            <Document className="position-relative" file={src}>
              <Page
                pageNumber={1}
                scale={pdfScale}
                renderAnnotationLayer={false}
                className="m-auto"
              />
            </Document>
          </>
        );
      }
      if (!["gif", "jpg", "png", "jpeg", "webp"].includes(tipo)) {
        return (
          <p className="my-3">
            Este tipo de archivo no se puede previsualizar. Por favor, descarga
            el archivo.
          </p>
        );
      }
      if (src === "") {
        return (
          <div className="align-items-center mb-3">
            <div className="spinner-border"></div>
            <span className="ml-3">Cargando vista previa...</span>
          </div>
        );
      }
      return (
        <img
          src={src}
          alt={adjunto.nombre}
          className="d-block m-auto mw-100 mt-4"
        />
      );
    }
  };

  const renderUserName = () => {
    if (adjunto !== null) {
      if (adjunto.usuario) {
        if (adjunto.usuario.nombre !== null) {
          return adjunto.usuario.nombre;
        }
        return adjunto.usuario.correo;
      }
    }
  };

  const renderDate = () => {
    return moment(adjunto.createdAt).utc().format("DD MMM YYYY HH:mm");
  };

  const handleCreateComment = (message, files) => {
    postEntrada(
      proyecto?.idProyecto,
      { message, files, idAdjunto: adjunto?.idAdjunto },
      fetchEntradas
    );
  };

  const getContainerHeight = () => {
    let tipo = adjunto?.tipo;
    if (tipo) tipo = String(tipo).toLowerCase();

    if (tipo === "pdf") return "calc(100vh - 166px)";
    return "max-content";
  };

  const renderScaleButtons = () => {
    if (adjunto?.tipo === "pdf") {
      return (
        <div className=" d-flex flex-row w-max-content">
          <button
            type="button"
            disabled={pdfScale <= 1}
            className="btn btn-primary w-max-content me-2"
            onClick={() => {
              setPdfScale(pdfScale - 1);
            }}
          >
            -
          </button>
          <button
            type="button"
            disabled={pdfScale >= 5}
            className="btn btn-primary w-max-content"
            onClick={() => {
              setPdfScale(pdfScale + 1);
            }}
          >
            +
          </button>
        </div>
      );
    }
  };

  const renderContent = () => {
    if (adjunto && adjunto !== null) {
      return (
        <div
          className="modal-body"
          style={{ height: "max-content", maxHeight: "calc(100vh - 56px)" }}
        >
          <div
            className="row flex-row"
            style={{ height: getContainerHeight(), minHeight: "600px" }}
          >
            <div className={`${commentsEnabled ? "col-8" : "col-12"} h-100`}>
              {renderView()}
            </div>
            <div className="col-4 ps-0 h-100">{renderComments()}</div>
          </div>

          <div className="row align-items-center">
            <div className="col-12 col-lg-12 my-2">
              <div className="d-flex justify-content-start align-items-center">
                <div className="w-75 overflow-hidden">
                  <h5 className="bold mb-0">
                    {adjunto !== null ? adjunto.nombre : "Adjunto"}
                  </h5>
                  <p className="mb-0">
                    {renderDate()} - {renderUserName()}
                  </p>
                </div>

                {renderScaleButtons()}
                <button
                  onClick={handleDownload}
                  className="btn btn-primary ms-auto me-2"
                >
                  <i className="fa fa-download"></i>
                </button>
                <button
                  onClick={() => setCommentsEnabled(!commentsEnabled)}
                  className="btn btn-primary"
                >
                  <i className="fa fa-comments"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link text-muted"
              onClick={clearSingleAdjunto}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                fontSize: "1rem",
              }}
            >
              <i className="fa fa-times" style={{ color: "#db8c63" }} />
            </button>
          </div>
          <div className="mt-3" style={{ maxWidth: "90%" }}></div>
        </div>
      );
    }
  };

  const renderComments = () => {
    if (commentsEnabled) {
      return (
        <div className="position-relative h-100" style={{ minHeight: "600px" }}>
          <Entradas
            max={max}
            idUsuario={user.idUsuario}
            idAdjunto={adjunto?.idAdjunto}
            scrollCallback={fetchEntradas}
            idProyecto={proyecto?.idProyecto}
            entradas={entradas?.filter(({ idAdjunto }) => idAdjunto === adjunto?.idAdjunto)}
          />
          <BitacoraInput
            placeholder="Escribe una Entrada"
            modifier={handleCreateComment}
          />
        </div>
      );
    }
  };

  return (
    <div
      role="dialog"
      tabIndex="-1"
      id="adjunto-view"
      data-bs-backdrop="static"
      aria-labelledby="modal"
      aria-hidden={adjunto === null ? "true" : "false"}
      className="modal modal-lg overflow-hidden fade"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{ maxWidth: commentsEnabled ? "calc(100vw - 40px)" : "" }}
      >
        <div className="modal-content rounded">
          {/* Añadir esta línea */}
          {renderContent()}
        </div>
        {/* Añadir esta línea */}
      </div>
    </div>
  );
};

export default AdjuntoView;
