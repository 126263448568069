export const unidades = {
  KG: ["pieza", "alto", "largo"],
  lote: ["pieza"],
  LOTE: ["pieza"],
  pieza: ["pieza"],
  PZA: ["pieza"],
  JOR: ["pieza"],
  VJE: ["pieza"],
  SAL: ["pieza"],
  PDA: ["pieza"],
  MOD: ["pieza"],
  ML: ["pieza", "largo"],
  FT2: ["pieza", "alto", "largo"], 
  M2: ["pieza", "alto", "largo"],
  M3: ["pieza", "alto", "largo", "ancho"]
};

export function getTipo(concepto) {
  if (concepto.extra) return "Extra";
  if(concepto.acumulado_autorizado && concepto.acumulado_autorizado !== null) {
    return concepto.acumulado_autorizado > concepto.importe ? "aditivo" : "normal";
  }
  if (concepto.autorizado && concepto.autorizado !== null) {
    if (
      parseFloat(concepto.autorizado) > parseFloat(concepto.cantidad) ||
      parseFloat(concepto.autorizadoActual) + parseFloat(concepto.autorizado) >
        parseFloat(concepto.cantidad)
    ) {
      return "aditivo";
    }
  }
  if(concepto.supervisado && concepto.supervisado !== null) {
    return concepto.supervisado > concepto.cantidad ? "aditivo": "normal";
  }
  if(concepto.estimado && concepto.estimado !== null) {
    return concepto.estimado > concepto.cantidad ? "aditivo": "normal";
  }
  return "Normal";
}

export const getConceptoEstimacionData = (conceptosEstimacion, conceptos) => {
  conceptosEstimacion = conceptosEstimacion.map((concepto) => {
    let conceptoEstimado = conceptos.find(
      (conceptoEst) =>
        parseInt(conceptoEst.idConcepto) === parseInt(concepto.idConcepto)
    );
    concepto = {
      ...concepto,
      estimadoAnterior:
        concepto.estimado !== null && concepto.estimadoAcumulado !== null
          ? parseFloat(concepto.estimadoAcumulado) -
            parseFloat(concepto.estimado)
          : concepto.estimado !== null
          ? concepto.estimado
          : 0,
      autorizadoAnterior:
        concepto.autorizado !== null && concepto.autorizadoAcumulado !== null
          ? parseFloat(concepto.autorizadoAcumulado) -
            parseFloat(concepto.autorizado)
          : concepto.autorizado !== null
          ? concepto.autorizado
          : 0,
      supervisadoAnterior:
        concepto.supervisado !== null && concepto.supervisadoAcumulado !== null
          ? parseFloat(concepto.supervisadoAcumulado) -
            parseFloat(concepto.supervisado)
          : concepto.supervisado !== null
          ? concepto.supervisado
          : 0,
      importeAnterior:
        concepto.importe_autorizado !== null ? concepto.importe_autorizado : 0,
    };
    if (conceptoEstimado) {
      concepto = {
        ...concepto,
        ...conceptoEstimado,
      };
    } else {
      concepto = {
        ...concepto,
        estimado: 0,
        autorizado: 0,
        supervisado: 0,
      };
    }
    if (!concepto.precio_autorizado || concepto.precio_autorizado === null) {
      if (conceptoEstimado) {
        if (
          conceptoEstimado.precio_autorizado &&
          conceptoEstimado.precio_autorizado !== null
        ) {
          concepto.precio_autorizado = conceptoEstimado.precio_autorizado;
        } else {
          concepto.precio_autorizado = concepto.precio;
        }
      } else {
        concepto.precio_autorizado = concepto.precio;
      }
    }

    if (concepto.subconceptos.length > 0) {
      const subconceptosData = concepto.subconceptos.map((obj) => {
        getConceptoEstimacionData();
      });
    }

    return concepto;
  });
};
