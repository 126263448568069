import React from "react";
import { formatMonto, getValue } from "../../utils";
import usePreciario from "../../hooks/usePreciario";

const ConceptoEstimacionDetail = () => {
  const { concepto, estimacion, renderPrecioControl } = usePreciario();

  const renderPrecio = () => {
    if (getValue(estimacion, "tipo") === "extra") {
      return (
        <>
          <div className="row align-items-center border">
            <div className="col bold bg-light py-2">Precio Solicitado</div>
            <div className="col">{formatMonto(concepto.precio_solicitado)}</div>
          </div>
          <div className="row align-items-center border">
            <div className="col bold bg-light py-2">Precio Control</div>
            <div className="col">{renderPrecioControl(concepto)}</div>
          </div>
        </>
      );
    }
    return (
      <div className="row align-items-center border">
        <div className="col bold bg-light py-2">Precio</div>
        <div className="col">{formatMonto(concepto.precio)}</div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row px-0">
        <p className="px-0">{concepto.nombre}</p>
      </div>
      <div className="row align-items-center border mb-3">
        <div className="col bold bg-light py-2">Unidad</div>
        <div className="col">{concepto.unidad}</div>
      </div>
      <div className="row px-0">
        <h5>Cantidades</h5>
      </div>
      <div className="row align-items-center border">
        <div className="col bold bg-light py-2">Contratista</div>
        <div className="col">{formatMonto(concepto.estimado)}</div>
      </div>
      <div className="row align-items-center border">
        <div className="col bold bg-light py-2">Supervisor</div>
        <div className="col">{formatMonto(concepto.supervisado)}</div>
      </div>
      <div className="row align-items-center border mb-3">
        <div className="col bold bg-light py-2">Obras</div>
        <div className="col">{formatMonto(concepto.autorizado)}</div>
      </div>
      <div className="row px-0">
        <h5>Importes</h5>
      </div>
      {renderPrecio()}
      <div className="row align-items-center border">
        <div className="col bold bg-light py-2">Importe Solicitado</div>
        <div className="col">${formatMonto(concepto.importe_solicitado)}</div>
      </div>
      <div className="row align-items-center border">
        <div className="col bold bg-light py-2">Importe Autorizado</div>
        <div className="col">${formatMonto(concepto.importe_autorizado)}</div>
      </div>
    </div>
  );
};

export default ConceptoEstimacionDetail;
