import React, { useContext, useEffect } from "react";
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
} from "@ionic/react";
import moment from "moment";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";
import SingleEstimacion from "./SingleEstimacion";
import { ModalContext } from "../../context/ModalContext";
import EstimacionForm from "../finanzas/EstimacionForm";

const Estimaciones = () => {
  const {
    estimacion,
    estimaciones,
    selectEstimacion,
    createEstimacion,
    getEstimacionesContrato,
  } = useContext(EstimacionesContext);
  const { modalComponent } = useContext(ModalContext);
  const { idContrato } = usePreciario();
  const { idProyecto } = useProyecto();

  useEffect(() => {
    fetchEstimaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);

  function fetchEstimaciones() {
    getEstimacionesContrato(idProyecto, idContrato);
  }

  function handleSelect(idEstimacion) {
    selectEstimacion(idProyecto, idContrato, idEstimacion);
  }

  function handleCreateEstimacion() {
    createEstimacion();
    modalComponent(
      "Agregar Estimacion",
      <EstimacionForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCallback={fetchEstimaciones}
      />
    );
  }

  const renderEstimaciones = () => {
    if (Array.isArray(estimaciones)) {
      return estimaciones.map(({ fecha, numero, idEstimacion }) => (
        <IonAccordion
          key={idEstimacion}
          value={idEstimacion}
          onClick={() => handleSelect(idEstimacion)}
        >
          <IonItem slot="header" color="light">
            <IonLabel>
              Estimación #{numero} - {moment(fecha).format("DD MMM YYYY")}
            </IonLabel>
          </IonItem>
          <div slot="content" className="container-fluid py-3 px-0">
            {estimacion === null || estimacion.idEstimacion !== idEstimacion ? (
              <div className="spinner-border" />
            ) : (
              <SingleEstimacion />
            )}
          </div>
        </IonAccordion>
      ));
    }
  };

  return (
    <div className="card p-3 shadow">
      <div className="row align-items-center mb-3">
        <div className="col-10">
          <h3 className="h5 mb-0">Estimaciones</h3>
        </div>
        <div className="col-2 text-end">
          <button
            key="agregar"
            onClick={handleCreateEstimacion}
            className="btn btn-outline-primary"
          >
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
      <IonAccordionGroup>{renderEstimaciones()}</IonAccordionGroup>
    </div>
  );
};

export default Estimaciones;
