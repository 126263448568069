export const getEstadosByPermiso = (permiso, estado) => {
  let options = [];
  switch (permiso) {
    case "finanzas":
      options = [
        {
          label: "Autorizada por Control",
          value: "autorizada-control"
        },
        {
          label: "Rechazada por Control",
          value: "rechazada-control",
        }
      ];
      break;
    case "gerencia":
      options = [
        {
          label: "Aprobada por Gerencia",
          value: "liberada",
        },
        {
          label: "Rechazada por Gerencia",
          value: "rechazada",
        },
      ];
      break;
    case "supervisor":
      options = [
        {
          label: "Aprobada por Supervisión",
          value: "revisada",
        },
        {
          label: "Rechazada por Supervisión",
          value: "rechazada",
        },
      ];
      break;
    case "obras":
      options = [
        {
          label: "Aprobada por Obras",
          value: "autorizada",
        },
        {
          label: "Rechazada por Obras",
          value: "rechazada",
        },
      ];
      break;
    case "contratista":
      if (estado === "autorizada") {
        options = [
          {
            label: "Estoy de acuerdo con la revisión de Obras",
            value: "conciliada-con-obras",
          },
          {
            label: "No estoy de acuerdo con la revisión de Obras",
            value: "rechazada",
          },
        ];
      } else if (estado === "revisada") {
        options = [
          {
            label: "Estoy de acuerdo con la revisión",
            value: "conciliada",
          },
          {
            label: "No estoy de acuerdo con la revisión",
            value: "rechazada",
          },
        ];
      } else if(estado === "autorizada-control") {
        options = [
          {
            label: "Estoy de acuerdo con la revisión de control",
            value: "conciliada-con-control"
          },
          {
            label: "No estoy de acuerdo con la revisión",
            value: "rechazada",
          },
        ];
      } else {
        options = [
          {
            label: "Enviar a Supervisión",
            value: "enviada",
          },
          {
            label: "Cancelar Versión",
            value: "cancelada",
          },
        ];
      }
      break;
    default:
      options = [
        {
          label: "Enviar a Supervisión",
          value: "enviada",
        },
        {
          label: "Cancelar Versión",
          value: "cancelada",
        },
      ];
  }
  options.unshift({ label: "Seleccionar estado", value: "" });
  return options;
};
