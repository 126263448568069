import React from "react";
import useFirmas from "../../hooks/useFirmas";

const FirmasEstimacionVersionMobile = () => {
  const { firmas } = useFirmas();

  return (
    <div className="firma-mobile container-fluid px-0">
      {firmas.map(({ name, renderFunction }, index) => (
        <div className="row" key={index}>
          <div className="col-4 border py-3">{renderFunction()}</div>
          <div className="col-8 border bg-light bold py-3">{name}</div>
        </div>
      ))}
    </div>
  );
};

export default FirmasEstimacionVersionMobile;
