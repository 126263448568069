import React, { useContext, useEffect } from "react";
import BitacoraInput from "../components/bitacora/BitacoraInput";
import { AppConfigContext } from "../context/AppConfigContext";
import { EntradasContext } from "../context/EntradasContext";
import AdjuntosService from "../services/AdjuntosService";
import Entradas from "../components/bitacora/Entradas";
import { MenuContext } from "../context/MenuContext";
import { AuthContext } from "../context/AuthContext";
import io from "socket.io-client";

let socket;

const Bitacora = ({ idProyecto }) => {
  const {
    max,
    entradas,
    getLength,
    getEntradas,
    postEntrada,
    appendEntrada,
    clearBitacora,
  } = useContext(EntradasContext);

  const { user } = useContext(AuthContext);

  const { BASE_URL } = useContext(AppConfigContext);

  const { setButtons, setSelected } = useContext(MenuContext);

  let step = 20;

  useEffect(() => {
    fetchEntradas();
    setupSockets();
    setButtons(null);
    getLength(idProyecto);
    setSelected({ title: "bitacora" });
    return () => {
      socket.disconnect();
      clearBitacora();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupSockets = () => {
    const url = BASE_URL.replace("/api", "");

    socket = io(url, {
      transports: ["websocket"],
    });

    socket.on("connection", () => {});

    socket.emit("join", {
      userId: user.uid,
      obraId: idProyecto,
    });

    socket.on("message", (entrada) => {
      appendEntrada(entrada);
    });
  };

  const uploadEntrada = (message, files) => {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let formData = new FormData();
        formData.append("adjunto", files[i]);
        AdjuntosService.postAdjuntoBitacora(idProyecto, formData).then(
          (res) => {
            const { idAdjunto } = res.data;
            postEntrada(idProyecto, { idAdjunto });
          }
        );
        if (message && message !== null && message !== "") {
          postEntrada(idProyecto, { message });
          socket.emit("sendMessage", {
            message,
            userId: user.uid,
            obraId: idProyecto,
          });
        }
      }
    } else {
      postEntrada(idProyecto, { message });
      socket.emit("sendMessage", {
        message,
        userId: user.uid,
        obraId: idProyecto,
      });
    }
  };

  const fetchEntradas = (offset) => {
    getEntradas(idProyecto, offset, step, {
      idConceptoMedicion: null,
      idConceptoUbicacion: null,
      idEstimacionVersion: null,
    });
  };

  return (
    <div className="container-fluid h-100 px-md-3 px-0 position-relative">
      <div className="row h-100" style={{ paddingBottom: "75px" }}>
        <Entradas
          scrollCallback={fetchEntradas}
          idUsuario={user.idUsuario}
          idProyecto={idProyecto}
          entradas={entradas}
          user={user}
          step={step}
          max={max}
        />
      </div>

      <BitacoraInput
        placeholder="Escribe una Entrada"
        modifier={uploadEntrada}
      />
    </div>
  );
};

export default Bitacora;
