import React from "react";
import useFirmas from "../../hooks/useFirmas";

const FirmasEstimacionVersion = () => {
  const {
    firmas,
  } = useFirmas();

  return (
    <div className="row">
      <div className="container-fluid px-0">
        <div className="row my-3">
          {firmas.map(({ name, renderFunction }) => (
            <div key={name} className="col-6 col-xl-3 px-0">
              <table className="table border">
                <thead className="bg-secondary bold">
                  <tr className="text-center">
                    <td style={{ height: 120 }}>RECIBE {name}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ height: 289 }}>{renderFunction()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FirmasEstimacionVersion;
