import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const UserItem = ({
  size,
  user,
  children,
  handleEdit,
  handleEditProfile,
  handleDelete,
  handleSignOut,
}) => {
  const { S3_ENDPOINT } = useContext(AppConfigContext);

  const renderImage = () => {
    if (user && user !== null) {
      const { Adjunto } = user;
      if (Adjunto && Adjunto !== null) {
        return (
          <img
            src={`${S3_ENDPOINT}/files/adjuntos/${Adjunto.idAdjunto}.${Adjunto.tipo}`}
            style={{ maxWidth: "40px", width: "40px", height: "40px", maxHeight: "40px" }}
            className="rounded-circle"
            alt={user.nombre}
          />
        );
      }
    }
    return (
      <i
        className="fa fa-user-circle"
        style={{ fontSize: size === "sm" ? "1.5em" : "2.5em" }}
      ></i>
    );
  };

  const renderName = () => {
    if (user && user !== null) {
      return user.nombre;
    }
  };
  return (
    <div className="">
      <div
        role="button"
        id="navbarDropdown"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        // onClick={handleSignOut}
        className="row align-items-center position-relative"
      >
        <div className="col ps-2 text-start">
          <div className="row mx-0 align-items-center">
            <div className="col-3">{renderImage()}</div>
            <div className="col-9 position-relative overflow-hidden">
              <p className="mb-0 bold user-item__name w-100">{renderName()}</p>
              {children}
            </div>
          </div>
        </div>

        {(handleEdit || handleDelete) && (
          <div className="col-2">
            {typeof handleEdit === "function" && (
              <button
                className="btn btn-sm text-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(user);
                }}
              >
                <i className="fa fa-edit"></i>
              </button>
            )}
            {typeof handleDelete === "function" && (
              <button
                className="btn btn-sm text-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(user);
                }}
              >
                <i className="fa fa-trash"></i>
              </button>
            )}
          </div>
        )}

        {typeof handleSignOut === "function" &&
          typeof handleEditProfile === "function" && (
            <ul
              id="dropdown"
              className="dropdown-menu user-menu"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <button className="dropdown-item" onClick={handleEditProfile}>
                  <i className="me-2 fas fa-edit"></i>
                  Editar
                </button>
                <button
                  className="dropdown-item text-danger"
                  onClick={handleSignOut}
                >
                  Salir <i className="ms-2 fa fa-sign-out-alt"></i>
                </button>
              </li>
            </ul>
          )}
      </div>
    </div>
  );
};

export default UserItem;
