import React, { useContext, useEffect, useState } from "react";
import ObraCard from "../proyectos/ObraCard";
import ObraClienteForm from "./ObraClienteForm";
import ObrasFilters from "../proyectos/ObrasFilters";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ProyectosContext } from "../../context/ProyectosContext";

const ObrasCliente = ({ idCliente, cliente }) => {
  const [query, setQuery] = useState("");
  const { user } = useContext(AuthContext);
  const { confirm, modalComponent } = useContext(ModalContext);

  const {
    options,
    postObra,
    proyectos,
    hideOptions,
    viewOptions,
    getProyectos,
    postProyecto,
    createProyecto,
    clearSingleProyecto,
  } = useContext(ProyectosContext);

  useEffect(() => {
    fetchProyectos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCliente]);

  const fetchProyectos = () => getProyectos({ idCliente });

  const handleCancel = () => {
    clearSingleProyecto();
    fetchProyectos();
  };

  const handleCreateProyecto = () => {
    createProyecto();
    modalComponent(
      "Agregar Obra",
      <ObraClienteForm idCliente={idCliente} handleCancel={handleCancel} />
    );
  };

  const handleDeleteProyectoCliente = (proyecto) => {
    proyecto.idCliente = null;
    postProyecto(proyecto);
  };

  const deleteProyectoCliente = (proyecto) => {
    confirm(
      `¿Eliminar el proyecto ${proyecto.nombre} para el cliente ${cliente.nombre}?`,
      () => handleDeleteProyectoCliente(proyecto)
    );
  };

  function renderObras() {
    if (Array.isArray(proyectos)) {
      let proyectosCliente = proyectos.filter(
        (proyecto) => parseInt(proyecto.idCliente) === parseInt(idCliente)
      );

      if (proyectosCliente.length === 0) {
        return <p>No hay proyectos asignados para este cliente.</p>;
      }

      if (query?.length > 0) {
        proyectosCliente = proyectosCliente.filter((proyecto) => {
          const proyectName = proyecto.nombre.toLowerCase();
          const text = query.toLowerCase();

          return proyectName.includes(text);
        });
      }

      return proyectosCliente.map((proyecto) => (
        <div key={proyecto.idProyecto} className="col-sm-12 col-lg-6 col-xl-4">
          <ObraCard
            user={user}
            options={options}
            postObra={postObra}
            proyecto={proyecto}
            idCliente={idCliente}
            viewOptions={viewOptions}
            hideOptions={hideOptions}
            handleDelete={deleteProyectoCliente}
          />
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  }

  return (
    <div className="container-fluid px-0">
      <ObrasFilters
        query={query}
        setQuery={setQuery}
        handleCreate={handleCreateProyecto}
      />{" "}
      <div className="row">{renderObras()}</div>
    </div>
  );
};

export default ObrasCliente;
