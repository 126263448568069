import React, { useState, useContext, useEffect } from "react";
import { PermisosContext } from "../context/PermisosContext";
import PermisoForm from "../components/permisos/PermisoForm";
import { ModalContext } from "../context/ModalContext";
import TabOptions from "../components/common/TabOptions"
import { CapacitorContext } from "../context/CapacitorContext";
import { MenuContext } from "../context/MenuContext";
import { UsuariosContext } from "../context/UsuariosContext";
import PermissionsList from "../components/permisos/PermissionsList";
import { isCoordinador } from "../utils/permisos";
import { AuthContext } from "../context/AuthContext";

const Permisos = () => {
  const [query, setQuery] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [filteredPermissions, setFilteredPermissions] = useState("");

  const { platform } = useContext(CapacitorContext);

  const [isTableView, setIsTableView] = useState(platform === "web");

  const { selectTabs, setSelected, setButtons } = useContext(MenuContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { users, getUsers } = useContext(UsuariosContext);
  const { user } = useContext(AuthContext);


  const {
    editPermiso,
    deletePermiso,
    createPermiso,
    permisos_proyecto,
    getPermisosProyectos,
  } = useContext(PermisosContext);

  useEffect(() => {
    selectTabs();
    getUsers();
    getPermisosProyectos();
    setSelected({ title: "permisos" });

    if (isCoordinador(user) && platform !== "web") {
      setButtons(
        <button className="btn btn-primary" onClick={handleCreate}>
          <i className="fa fa-plus"></i>
        </button>
      );
    }

    return () => {
      setButtons(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterPermissions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, permissions]);

  useEffect(() => {
    const newPermissions = getUsersPhotos();
    setPermissions(newPermissions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permisos_proyecto, users]);

  const getUsersPhotos = () => {
    const permissionsWithUserPhoto = permisos_proyecto?.map(item => {
      const userData = users?.find(user => {
        return user.idUsuario === item.idUsuario
      });

      return {
        ...item,
        idAdjunto: userData?.idAdjunto,
        tipo_adjunto: userData?.tipo_adjunto
      }
    });

    return permissionsWithUserPhoto;
  }

  const handleCancel = () => {
    editPermiso(null);
    clearModal();
  };

  const handleCreate = () => {
    clearModal();
    createPermiso();

    modalComponent(
      "Agregar Permiso",
      <PermisoForm handleCancel={handleCancel} />
    );
  };

  const handleEdit = (permiso) => {
    clearModal();
    editPermiso(permiso);

    modalComponent(
      "Editar Permiso",
      <PermisoForm handleCancel={handleCancel} />
    );
  };

  const handleDelete = (permiso) => {
    clearModal();

    modalComponent(
      'Precaución',
      <div className="container-fluid px-0">
        <p>
          {`¿Estás seguro que deseas eliminar el permiso de ${permiso.nombre_usuario} 
          para el proyecto ${permiso.nombre_proyecto}?`}
        </p>

        <div className="row">
  
          <div className="col-6 text-end ">
            <button className="btn w-100 btn-link text-muted" onClick={clearModal}>
              Cancelar
            </button>
          </div>

          <div className="col-6 ">
            <button
              className="btn btn-danger w-100"
              onClick={() => {
                deletePermiso(
                  permiso.idUsuario,
                  permiso.idProyecto,
                  () => {
                    getPermisosProyectos();
                    clearModal();
                  }
                );
              }}
            >
              Eliminar
            </button>
          </div>

        </div>
      </div>
    );
  };

  const filterPermissions = () => {
    if (query !== "") {
      const currentQuery = String(query).toLowerCase();
      const newFilteredPermissions = permissions.filter(
        ({ nombre_usuario, nombre_proyecto, correo }) =>
          String(nombre_usuario).toLowerCase().includes(currentQuery) ||
          String(nombre_proyecto).toLowerCase().includes(currentQuery) ||
          String(correo).toLowerCase().includes(currentQuery)
      );

      setFilteredPermissions(newFilteredPermissions);
    }
  }

  const renderPermissionsList = () => {
    let currentPermissions = permissions;

    if (query.length > 0) {
      currentPermissions = filteredPermissions;
    }

    return (
      <PermissionsList
        permissions={currentPermissions}
        query={query}
        platform={platform}
        isTableView={isTableView}
        editPermission={handleEdit}
        deletePermission={handleDelete}
      />
    )
  }

  const renderAddBtn = () => {
    if (isCoordinador(user));
    return (
      <div className="text-end">
        <button
          className="btn btn-primary"
          onClick={handleCreate}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    )
  }

  const renderMobileSearch = () => {
    if(platform !== 'web') {
      return (
        <div className="col-12 mt-3" style={{ height: 'max-content' }}>
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar permiso..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      )
    }
  }


  const renderTabOptions = () => {
    if(platform === 'web') {
      return (
        <TabOptions
          query={query}
          setQuery={setQuery}
          isTableView={isTableView}
          handleCreate={handleCreate}
          setIsTableView={setIsTableView}
          buttonCreateText={'+ NUEVO PERMISO'}
          searchInputPlaceholder={'Buscar permisos...'}
        />
      )
    } 
  }

  return (
    <div className="container-fluid mb-4 pb-3 view-container permisos-container">
      <div className="row align-items-start mx-auto w-100" >
        {renderMobileSearch()}
        <div className="col-12 px-0">
          {renderTabOptions()}
          <div className="row w-100 mx-auto">
            {renderPermissionsList()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permisos;
