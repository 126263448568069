import React, { createContext, useReducer, useContext } from "react";
import CambioEstimacionVersionesService from "../services/CambioEstimacionVersionesService";
import CambioEstimacionVersionesReducer from "../reducers/CambioEstimacionVersionesReducer";
import {
  SET_CAMBIOESTIMACIONVERSION,
  CREATE_CAMBIOESTIMACIONVERSION,
  CAMBIOESTIMACIONVERSIONS_RECEIVED,
  SET_PROPERTY_CAMBIOESTIMACIONVERSION,
} from "../types/cambioestimacionversiones";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  cambioestimacionversiones: null,
  cambioestimacionversion: null,
};

export const CambioEstimacionVersionesContext = createContext(initialState);

export const CambioEstimacionVersionesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    CambioEstimacionVersionesReducer,
    initialState
  );

  const { alert, success, clearModal } = useContext(ModalContext);

  const getCambioEstimacionVersiones = (idProyecto, idEstimacionVersion) => {
    CambioEstimacionVersionesService.getCambioEstimacionVersiones(
      idProyecto,
      idEstimacionVersion
    )
      .then((response) => {
        const { cambiosestimacionversion } = response.data;
        dispatch({
          type: CAMBIOESTIMACIONVERSIONS_RECEIVED,
          payload: cambiosestimacionversion,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCambioEstimacionVersion = (
    idProyecto,
    idCambioEstimacionVersion
  ) => {
    CambioEstimacionVersionesService.getSingleCambioEstimacionVersion(
      idProyecto,
      idCambioEstimacionVersion
    )
      .then((response) => {
        const { cambioestimacionversion } = response.data;
        dispatch({
          type: SET_CAMBIOESTIMACIONVERSION,
          payload: cambioestimacionversion,
        });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCambioEstimacionVersion = (cambioestimacionversion) => {
    dispatch({
      type: SET_CAMBIOESTIMACIONVERSION,
      payload: cambioestimacionversion,
    });
  };

  const createCambioEstimacionVersion = () => {
    dispatch({ type: CREATE_CAMBIOESTIMACIONVERSION });
  };

  const setPropertyCambioEstimacionVersion = (key, value) => {
    dispatch({
      type: SET_PROPERTY_CAMBIOESTIMACIONVERSION,
      payload: { key, value },
    });
  };

  const verifyCambioEstimacionVersion = (
    idProyecto,
    idCambioEstimacionVersion,
    code,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    CambioEstimacionVersionesService.verifyCambioEstimacionVersion(
      idProyecto,
      idCambioEstimacionVersion,
      code
    )
      .then(() => {
        success("cambioestimacionversion saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const saveCambioEstimacionVersion = (cambioestimacionversion, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = CambioEstimacionVersionesService.putCambioEstimacionVersion;
    if (isNaN(parseInt(cambioestimacionversion.idCambioEstimacionVersion))) {
      service = CambioEstimacionVersionesService.postCambioEstimacionVersion;
    }
    service(cambioestimacionversion)
      .then(() => {
        success("cambioestimacionversion saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteCambioEstimacionVersion = (
    idCambioEstimacionVersion,
    callback
  ) => {
    dispatch({ type: SHOW_SPINNER });
    CambioEstimacionVersionesService.deleteCambioEstimacionVersion(
      idCambioEstimacionVersion
    )
      .then(() => {
        success("cambioestimacionversion deleted.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const resendNotifications = (idProyecto, idCambioEstimacionVersion, callback) => {
    CambioEstimacionVersionesService.postNotificacionesCambioEstimacionVersion(
      idProyecto,
      idCambioEstimacionVersion
    )
      .then(() => {
        success("Notifcaciones reenviadas.");
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <CambioEstimacionVersionesContext.Provider
      value={{
        ...state,
        resendNotifications,
        setCambioEstimacionVersion,
        getCambioEstimacionVersiones,
        saveCambioEstimacionVersion,
        deleteCambioEstimacionVersion,
        createCambioEstimacionVersion,
        verifyCambioEstimacionVersion,
        getSingleCambioEstimacionVersion,
        setPropertyCambioEstimacionVersion,
      }}
    >
      {children}
    </CambioEstimacionVersionesContext.Provider>
  );
};
